@mixin flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
// .Navbar{
//     position: fixed;
// }
.stay{
    .SliderDepartamentos{
        .slick-slide {
            &::after{
                content: "";
                background-color: rgba(0, 0, 0, 0.02);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        
    }
}
.SliderDepartamentos{
    width: 100%;
    height: 18rem;
    position: relative;
    margin-top: 5rem;
    .slick-slider,.slick-list,.slick-track{
        height: 100%;
    }
    .slick-slide,.slick-slide > div{
        width: 100%;
        height: 100%;
    }
    .background.d-flex{
        display: flex!important;
        picture{
            width: 100%;
            img{
                object-fit: cover;
            }
        }
    }
    .background{
        height: 100%;
        width: 100%;
        object-fit: cover;
        .imagen1{
            position: relative;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        .logo{
            position: absolute;
            left: 36.5vw;
            height: 6.5vw;
            top: 5.8vw;
        }
        .contenido-ad{
            height: 100%;
            width: 40vw;
            z-index: 3;
            position: relative;
            left: 24vw;
            @include flex-center();
            padding-top: 1vw;
            padding-right: 1vw;
            flex-direction: column;
            .brand{
                img{
                    height: 11vw;
                    margin-bottom: 1.2vw;
                }
            }
            .title{
                color: var(--Yellow);
                font-size: 3.6vw;
                text-align: center;
                width: 75%;
                font-family: "nexa-heavy";
                line-height: 4.5vw;
                margin-bottom: 1.6vw;
            }
            .sub-title{
                color: white;
                font: 2vw;
                width: 50%;
                text-align: center;
                margin-bottom: 2vw;
                font-size: 1.3vw;
            }
            .certificado{
                svg{
                    height: 3.3vw;
                }
            }
        }
    }
    // .background::after{
    //     content: "";
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     background-color: #00000008;
    // }
    .container{
        position: relative;
    }
    .signal{
        position: absolute;
        left: 2rem;
        bottom: 0;
        display: flex;
        .btn{
            width: 2rem;
            height: 2rem;
            background-color: rgba(0, 0, 0, 0.459);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            @media(min-width:1400px){
                width: 3.5rem;
                height: 3.5rem;
            }
            .mask{
                background: white;
                width: 40%;
                height: 40%;
            }
        }
    }
}
@media(max-width:500px){
    .SliderDepartamentos{
        margin-top: 4.5rem;
        height: 10rem;
        .owl-prev,.owl-next{
            width: 4vw!important;
            height: 9vw!important;
        }
    }
}