.lampara{
    mask-image: url("../../Assets/iconos/lamp.svg");
}
.user{
    mask-image: url("../../Assets/iconos/user.svg");
}
.mail{
    mask-image: url("../../Assets/iconos/correo.svg");
}
.phone{
    mask-image: url("../../Assets/iconos/phone.svg");
}
.selected{
    mask-image: url("../../Assets/iconos/edificio.svg");
}
.icon-close{
    mask-image: url("../../Assets/iconos/btn-close.svg");
}

.Calculator{
    top: 0;
    left: 0;
    height: 100vh;
    width: 30%;
    background: black;
    z-index: 10;
    margin-top: 0vw;
    left: -32%;
    position: fixed;
    @media(max-width:740px){
        left: 0%;
        transform: scale(0);
        position: fixed;
    }
    @media (max-width:740px){
        width: 100%;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0;
    }
    .invalid {
        border-color: red!important;
    }
    
    .presentation-card{
        display: none;
        @media (max-width:740px){
            width: 100%;
            display: block;
        }
        .opacity{
            opacity: 1;
        }
    }
    .title{
        display: block;
        text-align: left;
        color: white;
        margin-top: 3vw;
        display: block;
        text-align: center;
        width: 100%;
        font-size: 1.2vw;
        @media(max-width:740px){
            margin-top: 0vw;
            font-size: 6vw;
        }
    }
    .sub-title{
        color: white;
        font-size: 1vw;
        text-align: center;
        margin-top: 1.1vw;
        @media(max-width:740px){
            font-size: 5.3vw;
        }
    }
    .form-calculator{
        padding-left: 1vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 19vw;
        @media(max-width:740px){
            width: 100%;
        }
        .content-input{
            margin-bottom: 0.5vw!important;
        }
    }
    .content-init-data{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        [type=checkbox].invalid ~ label{
            border-color: red;
        }
        #terminos,#terminos-y-condiciones{
            position: absolute;
            visibility: hidden;
        }
        #terminos:checked ~ .button-title{
            background: white;
        }
        .button-title{
            width: 1rem;
            height: 1rem;
            border-radius: 100%;
            border: 1px solid;
            margin-right: .5rem;
            cursor: pointer;
            position: relative;
            top: -0.15vw;
            @media(max-width:600px){
                top: -.1rem;
                position: relative;
            }
        }
        .radio{
            color: white;
            font-size: .7vw;
            margin-top: 1vw;
            display: flex;
            align-items: center;
            justify-content: center;
            @media(max-width:740px){
                margin-top: 5vw;
                width: 100%;
                font-size: 3.2vw;
            }

        }
    }
    .edit-data{
        color: white;
        cursor: pointer;
        display: none;
        margin-top: 1.3vw;
        font-size: .8vw;
        font-family: "nexa-book";
        opacity: .4;
        @media(max-width:740px){
            font-size: 5vw;
            margin-bottom: 2vw;
        }
        .icon-back{
            mask-image: url("../../Assets/iconos/flecha-izquierda.svg");
            mask-size: contain;
            mask-position: center;
            mask-repeat: no-repeat;
            width: 1vw;
            height: 1vw;
            background: white;
            margin-right: .5vw;
            margin-bottom: .1vw;
            transition: .5s;
            @media(max-width:740px){
                width: 5vw;
                height: 5vw;
                margin-right: 2vw;
                margin-bottom: .2vw;
            }
        }
    }
    .edit-data.active{
        display: flex;
        align-items: center;
    }

    .edit-data:hover{
        opacity: .6;
        transform: translateX(-10px);
        .icon-back{
        }
    }
    .content-init-data.hide{
        display: none;
    }
    .message-calculator{
        width: 100%;
        @media(max-width:740px){
            width: 85%;
            margin: auto;
            padding-top: 25vw;
        }
        .message{
            width: 17vw;
            @media(max-width:740px){
                width: auto;
            }
        }
        .form-init{
            .next-step{
                border-radius: 100rem;
                background-color: var(--red);
                border: 1px solid var(--red);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto;
                margin-top: 1vw;
                margin-top: 2vw;
                position: relative;
                left: 0;
                right: 0;
                @media(max-width:740px){
                    margin-top: 8vw;
                    margin-bottom: 12vw;
                }
            }
            .next-step.hide{
                display: none;
            }
            .next-step:hover{
                background-color: var(--red);
                border: 1px solid var(--red);
            }
        }
        .content-ask{
            display: none;
        }
        .content-ask.active{
            display: block;
        }
        
    }
    .content-close-button{
        @media(max-width:740px){
            width: 100%;  
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            position: absolute;
            top: 0;
        }
    }
    .close-calculator{
        width:2.5vw;
        height:2.5vw;
        background: transparent;
        position: absolute;
        right: 1vw;
        z-index: 1;
        top: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 1s;
        @media(max-width:740px){
            width: 3rem;
            max-width: 3rem;
            height: 3rem;
            min-height: 3rem;
            left: 0;
            top: 0;
            position: relative;
            border-radius: 100%;
            margin: 1rem 0;
            background-color: white;
            box-shadow: 0px 0px 26px -2px rgba(128, 127, 127, 0.295);
        }
        .icon-close{
            width: 35%;
            height: 35%;
            background: rgba(255, 255, 255, 0.555);
            pointer-events: none;
            mask-repeat: no-repeat;
            @media(max-width:740px){
                background: #808080b6!important
            }
        }
        
    }
    .close-calculator:hover{
        .icon-close{
            background: white;            
        }
    }
    .success-calculate{
        display: none;
    }
    .content-calculator{
        width: 100%;
        height: 100%;
        margin-left: auto;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        overflow-y: scroll;
        padding-left: 28%;
        padding-bottom: 4rem;
        padding-top: 5rem;
        @media(max-width:740px){
            width: 100%!important;
            margin-right: auto;
            padding-top: 0vw;
            padding-left: 0;
        }
        .content-input{
            width: 100%;
            position: relative;
            margin-bottom: 1.6vw;
            @media(max-width:740px){
                width: 100%!important;
                margin-bottom: 4vw;
            }
        }
        .tip{
            font-size: .8vw;
            font-weight: 100;
            font-family: "nexa-book";
            opacity: .4;
            display: flex;
            @media(max-width:740px){
                font-size: 4vw;
            }
            .icon-tip{
                mask-position: center;
                mask-size: contain;
                mask-repeat: no-repeat;
                width: 1vw;
                height: 1vw;
                min-width: 1vw;
                background: white;
                margin-right: .3vw;
            }
        }
        .input{
            width: 100%;
            background:transparent;
            border: 0ch;
            border-bottom: 1px solid rgba(255, 255, 255, 0.295);
            color: #999999;
            font-family: "nexa-book";
            padding: .5vw 1.5vw;
            padding-top: .9vw;
            padding-right: 0;
            padding-bottom: 0.4vw;
            font-size: .9vw;
            @media(max-width:740px){
                padding: 4vw 6vw;
                padding-left: 8vw;
                font-size: 4.5vw;
            }
        }
        .nombre{
            width: 45%;
        }
        .apellido{
            width: 45%;
        }
        .icon{
            width: .8vw;
            height: .8vw;
            background: white;
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: bottom;
            position: absolute;
            top: auto;
            bottom: .9vw;
            margin: auto;
            @media(max-width:740px){
                width: 5vw;
                height: 5vw;
                top: 0;
                bottom: 1.5vw;
                mask-position: center;
            }           
        }
        .input{
            font-family: "nexa-book";
            color: white;
            opacity: 1;

        }
        .input::placeholder{
            font-family: "nexa-book";
            color: white;
            opacity: .6;
        }
        .content-select{
            display: flex;
            flex-direction: column;
            color: white;
            background: #00000029;
            padding-left: 1vw;
            padding-right: 1vw;
            padding-top: 1.5vw;
            padding-bottom: 1.5vw;
            width: 17vw;
            margin-top: 1.5vw;
            margin-bottom: 1vw;
            @media(max-width:740px){
                width: 100%;
                padding-top: 4vw;
                padding-bottom: 4vw;
                padding-left: 6vw;
                margin-top: 8vw;
                margin-bottom: 8vw;
            }
            .title-1{ 
                font-size: 1.1vw;
                @media(max-width:740px){
                    font-size: 6vw;
                }
            }
            .title-2{
                font-size: .8vw;
                margin-bottom: .6vw;
                @media(max-width:740px){
                    font-size: 4.5vw;
                    margin-bottom: 4vw;
                }
            }
            .title-3{
                font-size: .8vw;
                margin-bottom: .1vw;
                font-weight: 100;
                font-family: "nexa-book";
                margin-top: 1vw;
                @media(max-width:740px){
                    margin-top: 4vw;
                    font-size: 6vw;
                }
            }
            .title-4{
                font-size: 1.1vw;
                margin-bottom: 0;
                @media(max-width:740px){
                    font-size: 6vw;
                }
            }
            .nota{
                color:rgba(255, 255, 255, 0.336);
                font-size: .6vw;
                margin-top: .8vw;
                font-family: "nexa-book";
                @media(max-width:740px){
                    font-size: 3vw;
                }
            }
            .nota-1{
                width: 100%;
                margin-bottom: 1vw;
                text-align: left;
            }
        }
        .content-select.mt-05{
            margin-top: .5vw;
        }
        select{
            opacity: .6;
            background: transparent;
            border: 0ch;
            border-bottom: 1px solid rgba(255, 255, 255, 0.295);
            font-family: "nexa-book";
            font-size: .9vw;
            width: 9vw;
            padding-top: .9vw;
            padding-bottom: 0.5vw;
            padding-right: 0vw;
            color: white;
            @media(max-width:740px){
                font-size: 5vw;
                padding-bottom: 3vw;
                padding-top: 3vw;
                padding-right: 3vw;
                margin-bottom: 2vw;
                width: 45vw;
            }
        }
        option{
            color: black;
            padding: .2vw;
        }
        .ask{
            width: 17vw;
            padding-left: 1vw;
            font-size: .9vw;
            @media(max-width:740px){
                font-size: 5vw;
                width: 100%;
            }
            .content-input{
                select{
                    width: 100%;
                }
            }
            .input{
                padding-left: 0;
                width: 9vw;
                @media(max-width:740px){
                    width: 40vw;
                }
            }
            span{
                color: white;
                @media(max-width:740px){
                    margin-bottom: 2vw;
                }
            }
            .btn-calcular{
                display: block;
                margin-left:auto;
                margin-right:auto;
            }
            .btn-calcular:hover{
                background: var(--red);
                border: 1px solid  var(--red);
            }
        }
        .content-answer{
            display: flex;
            margin-top: .5vw;
            margin-bottom: 2vw;
            @media(max-width:740px){
                margin-right: 0;
                margin-left: 0;
            }
            input[type="radio"]{
                position: absolute;
                visibility: hidden;
            }
            #si:checked ~ .answer.si,#no:checked ~ .answer.no{
                background: white;
                color: var(--Secondary);
            }
            .answer{
                width: 3vw;
                height: 2.5vw;
                display: flex;
                align-items: center;
                justify-content: center;
                background: transparent;
                border: 1px solid #999999;
                margin-right: 1vw;
                color: white;
                cursor: pointer;
                @media(max-width:740px){
                    // width: 5vw;
                    width: 14vw;
                    padding: 0 4vw;
                    height: 12vw;
                    margin-top: 4vw;
                    margin-right: 4vw;
                }
            }
            .answer:hover{
                background: white;
                border:1px solid white;
                color: #323231;
            }
        }
        .constrast{
            font-family: "nexa-heavy";
            color:var(--third)
        }
        .btn-calcular{
            border-radius: 100rem;
            background-color: transparent;
            color: white;
            height: 3vw;
            padding: 0 1vw;
            border: 1px solid white;
            font-size: .8rem;
            cursor: pointer;
            transition: .3s;
            width: 8vw;
            font-family: "nexa-book";
            line-height: .9rem;
            @media(max-width:740px){
                font-size:4.5vw;
                line-height: 4.5vw;
                height: 12vw;
                margin-top: 6vw;
                margin-bottom: 16vw;
                padding: 0 4vw;
                width: 40vw;
            }
        }
        .btn-calcular:hover{
            background: var;
            color: white;
            border: 1px solid #C69C6D;
        }
    }
    .content-calculator::-webkit-scrollbar {
        width: .4vw;
        @media(max-width:740px){
            width: 0vw;
        }
    }
    .content-calculator::-webkit-scrollbar-thumb {
        background: var(--red);
        border-radius: .8vw;
    }
    .content-calculator::-webkit-scrollbar-track {
        background: #00000029;
    }
    .content-calculator.active{
        .calculate-init{
            display: none;
        }
        .success-calculate{
            display: flex;
            flex-direction: column;
        }
    }
    .message{
        // padding-left: 1vw;
        .m-title-1{
            font-size: .9vw;
            color: white;
            width: 100%;
            display: block;
            text-align: center;
            margin-bottom: 1vw;
            margin-top: 2.5rem;
            font-family: "nexa-book";
            @media(max-width:740px){
                margin-top: 6vw;
                font-size: 4vw;
                width: auto;
                line-height: 6vw;
                margin-bottom: 5vw;
            }
        }
        
        
        .amount{
            display: block;
            font-size: 1.2vw;
            color: var(--red);
            margin-top: .5vw;
            text-align: center;
            font-family: "nexa-heavy";
            @media(max-width:740px){
                font-size: 5vw;
                margin-top: 3vw;
                margin-bottom: 3vw;
            }
        }
    }
    .nota{
        margin-top: 1rem;
        font-family: "nexa-book";
        font-size: .7vw;
        color: rgba(255, 255, 255, 0.329);
        display: block;
        line-height: 110%;
        width: 17vw;
        margin-top: 0vw;
        text-align: center;
        margin-bottom: 2vw;
        @media(max-width:740px){
            font-size: 3vw;
            width: 85vw;
        }
    }
    .m-title-2,.m-title-3{
        font-size: .8vw;
        color: white;
        display: block;
        text-align: center;
        font-family: "nexa-book";
        @media(max-width:740px){
            font-size: 4vw;
            line-height: 5vw;
        }
    }
    .message-center{
        padding-right: 1vw;
        .m-title-3{
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .content-button{
        display: flex;
        .btn-calcular{
            margin-right: 1vw;
            width: 8vw;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            @media(max-width:740px){                
                width: 40vw;
            }
        }
        .recalculate{
            @media(max-width:740px){                
                margin-right: 6vw;
            }
        }
        .separe{
            background-color: var(--red);
            border: 1px solid var(--red);
            @media(max-width:740px){                
                margin-right: 0vw;
            }
        }
        .recalculate:hover,.separe:hover{
            background: var(--red);
            border: 1px solid var(--red);
        }
    }
}
.Calculator.open{
    left: 0%;
    position: fixed;
    @media(max-width:740px){
        animation: .8s openCalculateMovil forwards;
    }
}
@keyframes openCalculate{
    0%{
        left: -32%;
    }
    99%{
        left: 0%;
        top: 0;
        position: relative;
    }
    100%{
        position: sticky;
        top: 5vw;
    }
}
@keyframes openCalculateMovil{
    0%{
        transform: scale(0);
        opacity: 0;
        z-index: -1;
    }
    30%{
        z-index: 21;
        opacity: .8;
    }
    80%{
        opacity: 1;
    }
    100%{
        transform: scale(1);
    }
}
.Calculator.close{
    animation: 1s closeX forwards;
    position: fixed;
    @media(max-width:740px){
        animation: .6s closeScale forwards;
        position: fixed;
    }
    @keyframes closeScale {
        0%{
            transform: scale(1);
            opacity: 1;
        }
        50%{
            opacity: 0;
            display: none;
            visibility: hidden;
        }
        100%{
            transform: scale(0);
            z-index: -1;
        }
    }
}
@keyframes closeX{
    0%{left: 0%;}
    100%{left: -32%;}
}