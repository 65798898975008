.icon-download{
    // background: ;
}
.btn-download{
    border: 1px solid gray;
    border-radius: .7rem;
    display: flex;
    padding: .5rem .7rem;
    .icon-button{
        width: 1.6rem;
        height: 1.9rem;
        margin-right: .2rem;
        .icon-download{
            height: 100%;
        }
    }
    .text-button{
        color: black;
        font-family: "nexa-bold";
        display: flex;
        flex-direction: column;
        font-size: .7rem;
        .peso{
            color: black;
            font-size: .6rem;
            line-height: 0.8rem;
            margin-top: .1rem;
        }
    }
}