.icon-buy{
    mask-image: url("../../Assets/iconos/cart-buy.svg");
}
.c-white{
    color: white;
}

.pencil{
    mask-image: url("../../Assets/iconos/pencil.svg");
}
.select{
    margin-top: 1.2rem;
    margin-bottom: 1.5rem;
    border: 0;
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: .6rem;
    padding-left: .6rem;
    font-size: 1rem;
    width: 100%;
    
    color: #666666;
    @media(max-width:740px){
        padding-top: .6rem;
        padding-right: 1rem;
    }
}

.content-all-cars{
    width: 100%;
    display: flex;
}
.separar-depa{
    background:#f7f7f7;
    padding-top: 4rem;
    display: flex;
    flex-wrap: nowrap;
    @media(max-width:1200px){
        flex-wrap: wrap;
    }
    @media(max-width:940px){
         flex-wrap: wrap;
         width: 100%;
     }
    --gray:#E6E6E6;

    .icon{
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
    }
    .title{
        display: flex;
        font-size: 1.4rem;
        align-items: center;
        color: #323231;
        letter-spacing: .2rem;
        @media(max-width:740px){
            font-size: 1.1rem;
            margin-left: 0;
        }
    }
    .sub-title{
        font-size: 1rem;
        color: #4D4D4D;
    }
    .title-select{
        font-size: 1rem;
        color: #666666;
        display: flex;
        @media(max-width:740px){
            font-size: .9rem;
        }
    }
    .content-text{
        display: flex;
        flex-direction: column;
        width: 50%;
        justify-content: center;
        .title-text{
            font-size: 1.2rem;
            margin-bottom: .5rem;
            width: 100%;
        }
        .inf-content-text{
            display: flex;
            flex-direction: column;
            width: 50%;
            &.dormitorio,&.area,&.vista{
                @media(max-width:1400px){
                    width: 7rem;
                }
                @media(max-width:500px){
                    margin-bottom: 1rem;
                    .subtitle-text{
                        margin-bottom: 0;
                    }
                }
            }
            &.dormitorio-2,&.area{
                width: 7rem;
                @media(max-width:1600px){
                    width: 5rem;
                }
                @media(max-width:500px){
                    width: 20vw;
                }
            }
            &.dormitorio-1,&.piso{
                margin-right: 1rem;
                @media(max-width:1600px){
                    margin-right: 0rem;
                }
                @media(max-width:1400px){
                    min-width: 13rem;
                }
                @media(max-width:500px){
                    min-width: 48vw;
                }
            }
            &.piso{
                
                @media(max-width: 1050px){
                    width: 14rem;
                }
                @media(max-width:500px){
                    width: 10rem;
                    margin-bottom: 1rem;
                    select{
                        width: 10.5rem!important;
                        padding-right: 0;   
                        padding-top: 0;
                    }
                }
            }
        }
        .subtitle-text{
            font-size: .8rem;
            margin-bottom: 1rem;
        }
        .subtitle-text-2{
            margin-bottom: 0.3rem;

        }
        .select{
            width: auto;
            background-color: white;
            color: #666666;
        }
    }
   
    .cart-buy-container{
        background-color: white;
        width: 50%;
        min-width: 28rem;
        padding-top: 3rem;
        padding-bottom: 2rem;
        display: flex;
        justify-content: flex-end;
        padding-left: 1rem;
        
        @media(max-width:1200px){
            width: 55%;
        }
        @media(max-width:1100px){
            width: 100%;
            min-width: inherit;
            justify-content: flex-start;
        }
        @media(max-width:500px){
            padding-left: 0;
            padding-top: 0;
        }
        .content-cart{
            margin-right: 2.8rem;
            @media(max-width:1100px){
                margin-right: 1.5rem;
            }
            @media(max-width:1100px){
                width: 100%;
            }
            @media(max-width:500px){
                margin-right: 0;
            }
        }
        .title{
            width: 76%;
            margin-top: 0.3rem;
            margin-bottom: 1.3rem;
            @media(max-width:740px){
                margin-left: 0;
                margin-right: 0;
                align-items: center;
                justify-content: center;
                display: flex;
                text-align: center;
                width: 100%;
                margin-top: 3rem;
                margin-bottom: 2rem;
            }
        }
        .icon-buy{
            width: 1.5rem;
            height: 1.5rem;
            background: black;
            margin-right: .4rem;
            @media(max-width:740px){
                width: 1.2rem;
                height: 1.2rem;
            }
        }
        .m-light{
            position: relative;
            top: .3rem;
        }
        .flex-row{
            display: flex;
            flex-direction: row;    
            flex-wrap: wrap;
        }
        .content-card{
            display: flex;
        }
        
        .p-absolute{
            position: absolute;
        }
        .flex-column{
            display: flex;
            flex-direction: column;
        }
        .container-card{
            display: flex;
            width: 32rem;
            margin-left: auto;
            flex-direction: column;
            border: 2px solid #E6E6E6;
            margin-right: 0rem;
            @media(min-width:1400px){
                width: 41rem;
                margin-right: 3rem;
            }
            @media(max-width:1200px){
                width: 30rem;
                margin-right: auto;
            }
            @media(max-width:1100px){
                width: 100%;
            }
            @media(max-width:740px){
                width: 90%;
            }
            .flex-row{
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
            .content-items-card{
                width: 100%;
                max-height: 47vh;
                overflow-y:scroll ;
            }
            .content-items-card::-webkit-scrollbar {
                width: .5rem; 
            }
            .content-items-card::-webkit-scrollbar-thumb {
                background: rgba(33, 37, 41, 0.075);
                border-radius: .2rem;
            }
            .content-items-card::-webkit-scrollbar-track {
                background: #0633770e;
            }
            
            .sub-container-card{
                position: relative;
                padding-bottom: 1.3rem;
                display: flex;
                flex-direction: column;
                max-height: 460px;
                overflow-y: scroll;
                overflow-x: inherit;
                padding-left: 1rem;
                padding-right: 1rem;
                
                .content-all-cards{
                    border-bottom: 1px solid rgba(224, 224, 224, 0.473);
                    padding: .8rem 0;
                    padding-top: .9rem;
                    padding-bottom: 0;
                    @media(max-width:500px){
                        display: flex;
                        flex-direction: column-reverse;
                    }
                }
                .content-text{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    height: 100%;
                    justify-content: flex-start;
                    .select{
                        margin-top: 0;
                        margin-bottom: 0rem;
                        padding-left: 0;
                        font-size: .8rem;
                        height: auto;
                        padding-bottom: 0;
                        width: 12rem;
                    }
                    .subtitle-text-3{
                        margin-bottom: 0;
                    }
                }
                .delete_item{
                    margin-left: 1rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    @media(max-width:1200px){
                        position: absolute;
                        top: -7rem;
                        right: 0;
                        width: 1.1rem;
                    }
                    @media(max-width:1100px){
                        top: 0;
                    }
                    @media(max-width:500px){
                        margin-top: 0;
                        top: .8rem;
                    }
                }
            }
           
            ////
            
            .sub-container-card:last-child::after{
                display: none;
            }
            .content-img{
                width: 12rem;
                min-width: 12rem;
                margin-right: 2rem;
                @media(max-width:1400px){
                    width: 7rem;
                    min-width: 7rem;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .cuota{
                width: 100%;
                position: relative;
                display: flex;
                &::before{    
                    content: "";
                    background-color: #0009;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 1;
                    pointer-events: none;
                }
                img{
                    width: 100%;
                    position: absolute;
                    height: 100%;
                    object-fit: cover;
                    object-position: right;
                }
                .content-text-cuota{
                    width: 100%;
                    position: relative;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    z-index: 2;
                    .c-white{
                        margin-bottom: 1rem;
                    }
                    .btn-aplicar{
                        width: 5rem;
                        height: 2.5rem;
                        min-width: 5rem;
                        background-color: transparent;
                        border: 1px solid white;
                        color: white;
                        font-size: 1rem;
                    }
                    .btn-aplicar:hover{
                        background-color: white;
                        color: var(--Secondary);
                        cursor: pointer;
                    }
                    .content-input{
                        margin-top: 0;
                        margin-bottom: 0;
                        display: flex;
                        width: 66%;
                        input{
                            border-bottom: 2px solid #ffffff29;
                            color: white;
                            margin-right: 1rem;
                            font-size: .8rem;
                        }
                        input::placeholder{
                            font-size: .8rem;
                            color: white;
                        }
                    }
                   
                }
            }
            .content-amount{
                padding-top: .6rem;
                padding-bottom: .6rem;
                border-bottom: 2px solid #E6E6E6;
                .flex-row{
                    justify-content: space-between;
                    padding-top: .4rem;
                    padding-bottom: .4rem;
                }
            }
            .content-amount-2{
                border-bottom: 0px;
            }
        }
    }
    .content-input{
        width: 100%;
        margin-bottom: 1.5rem;
        @media(max-width:500px){
            margin-bottom: 1rem;
        }
        .invalid {
            border-color: red;
        }

        select{
            font-size: .8rem;
            margin-bottom: 0;
        }
        
        input{
            font-size: .8rem;
            width: 100%;
            border: 0;
            border-bottom: 2px solid #E6E6E6;
            padding: 0.5rem;
            font-family: "nexa-book";
            background: transparent;
        }
        input::placeholder{
            font-size: .8rem;
            font-family: "nexa-book";
        }
    }
    .dni{
        width: 48%;
    }
    .codigo-postal{
        width: 48%;
    }
    .information{
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-top: 4rem;
        padding-left: 3rem;
        padding-bottom: 3rem;
        @media(max-width:740px){
            padding-left: 0rem;
            width: 100%;
        }
        @media(max-width:1100px){
            width: 100%;
            padding-left: 1.6rem;
        }
        @media(max-width:500px){
            padding-left: 0;
        }
        .title{
            margin-bottom: 1rem;
            @media(max-width:740px){
                justify-content: center;
            }
        }
        .flex-row{
            @media(max-width:740px){
                align-items: center;
                justify-content: center;
            }
            @media(max-width:500px){
                // flex-direction: column-reverse;
            }
        }
        .target{
            width: 27rem;
            margin-right: 1rem;
            @media(max-width:740px){
                width: 90%;
                margin: auto;
            }
            @media(max-width:500px){
                width: 100%;
            }
            @media(max-width:1600px){
                order: 2;
            }
            @media(max-width:900px){
                margin: auto;
            }
            .img-visa{
                width: 6rem;
                margin-bottom: 1rem;
            }
            .img-question{
                width: 1.3rem;
                position: relative;
                margin-left: .3rem;
            }
            
            .separe-item{
                width: 100%;
                align-items: center;
                font-size: 1rem;
            }
            .data-user{
                width: 100%;
                height: 0rem;
                transition: .5s;
                overflow: hidden;
                animation: .5s contractDown forwards;
            }
            .data-user.active{
                animation: .5s expandDown forwards;
            }
            .target-facture{
                width: 20rem;
                height: 0rem;
                animation: .5s contractComprovante forwards;
            }
            .target-facture.active{
                animation: .5s expandComprovante forwards;
            }
            @keyframes contractDown {
                0%{
                    height: 17.5rem;
                }
                100%{
                    height: 0rem;
                }
            }
            @keyframes expandDown {
                0%{
                    height: 0rem;
                }
                100%{
                    height: 16.7rem;
                }
            }
            @media(max-width:500px){
                @keyframes expandDown {
                    0%{
                        height: 0rem;
                    }
                    100%{
                        height: 14.7rem;
                    }
                }
            
            }
            @keyframes expandComprovante {
                0%{
                    height: 0rem;
                }
                100%{
                    height: 20rem;
                }
            }
            @keyframes contractComprovante {
                0%{
                    height: 20rem;
                }
                100%{
                    height: 0rem;
                }
            }
            .separe-block{
                width: 100%;
                padding-top: 1rem;
                padding-bottom: 1rem;
                display: flex;
                align-items: center;
                background: white;
                padding-left: 1.3rem;
                padding-right: 1.3rem;
                margin-bottom: .8rem;
                overflow: hidden;
                .icon{
                    width: 2.2rem;
                    min-width: 2.2rem;
                    height: 2.2rem;
                    padding: 0.6rem;
                    background: var(--gray);
                    border-radius: 100%;
                    margin-right: .5rem;
                    .user,.docs{
                        width: 100%;
                        height: 100%;
                        background: black;
                        mask-position: center;
                        mask-size: contain;
                        mask-repeat: no-repeat;
                    }
                }
                .icon-out-border{
                    width: 1.8rem;
                    max-width: 1.8rem;
                    max-height: 1.8rem;
                    height: 1.8rem;
                    padding: .5rem;
                    margin-left: auto;
                }
                .pencil{
                    width: 100%;
                    height: 100%;
                    background: black;
                    mask-position: center;
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    cursor: pointer;
                }
                .content-select{
                    width: 100%;
                    select{
                        font-size: .8rem;
                    }
                }
               
                .doble-content{
                    display: flex;
                    justify-content: space-between;
                }
                .content-input-double{
                    width: 100%;
                    @media(max-width:600px){
                        width: 100%;
                    }
                }
                
                .method_pay{
                    width: 100%;    
                    height: auto;
                    position: relative;
                    .loader{
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: #fff;
                        z-index: 2;
                    }
                }
                .method_pay.active{
                    height: 19.5rem;
                }
                #myPaymentForm{
                    width: 100%;
                    button{
                        width: 100%;
                    }
                }
                #myPaymentForm > div{
                    width: 100%;
                }
                .loader{
                    display: none;
                }
                .loader.active{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // animation: temporate 2s forwards;
                    &.active{
                        display: flex;
                    }
                    .loading{
                        height: 4rem;
                        width: 4rem;
                        color: rgba(0, 30, 77, 0.08);
                        position: relative;
                        display: inline-block !important;
                        border: 4px solid;
                        border-radius: 50% !important;
                        border-right-color: rgba(0, 30, 77, 0.2);
                        margin-top: -24px !important;
                        margin-left: -24px !important;
                        -webkit-transform: rotate(0);
                        transform: rotate(0);
                        -webkit-animation: pix-loading 1s ease-in-out infinite !important;
                        animation: pix-loading 1s ease-in-out infinite !important;
                        pointer-events: none;
                        z-index: 1000000000000000000000;
                        box-sizing: border-box !important;
                        will-change: transform;
                    }
                }
                @keyframes pix-loading {
                    0% {
                        -webkit-transform: rotate(0);
                        transform: rotate(0);
                    }
                    
                    100% {
                        -webkit-transform: rotate(360deg);
                        transform: rotate(360deg);
                    }
                }
                @keyframes temporate {
                    0%{
                        opacity: 1;
                    }
                    89%{
                        opacity: 1;
                        z-index: 101;
                        visibility: visible;
                    }
                    100%{
                        opacity: 0;
                        z-index: -0;
                        visibility: hidden;
                        
                    }
                }
            }
            .separe-block-2{
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
            .separe-block.flex-column{
                align-items: flex-start;
            }
            .btn-submit,.btn-submit-facture{
                width: 100%;
                height: 3.5rem;
                border: 0;
                font-size: 1rem;
                background: #E6E6E6;
                color: #4D4D4D;
                cursor: pointer;
                transition: .5s;
            }

            .btn-submit-facture:hover,.btn-submit:hover{
                background: #C69C6D;
                color: white;
            }
        }

        .target-facture{
            width: 100%;
            width: 20rem;
            @media(max-width:1600px){
                width: 27rem;
                order:1;
            }
            @media(max-width:500px){
                width: 100%;

            }
        }
        .facture{
            display: flex;
            @media(max-width:740px){
                margin-top: 0rem;
            }
            .type-recibo{
                padding-top: 1rem;
                padding-bottom: 1rem;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                background: white;
                opacity: .4;
                cursor: pointer;
                position: relative;
            }
            .type-recibo.active{
                opacity: 1;
                background: white;
            }
            .type-recibo::after{
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0%;
                height: 1px;
                background: black;
                transition: .5s;
            }
            .type-recibo.active::after{
                width: 100%;
            }
            
        }
        .select-receipt{
            .content-recibo-1,.content-recibo-2{
                display: none;
            }
        }
        .select-receipt.boleta{
            .content-recibo-1{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
        .select-receipt.factura{
            .content-recibo-2{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
    }
}