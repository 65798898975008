$title : 4.5vw;
.Information.unity{
    --light:#ff7f2f;
    --text:white;
    --dark:hsl(207, 100%, 17%);
    --Yellow:#ff7f2f;
    
    .titles{
        font-family: "nexa-bold"!important;
        .bold{
            font-family: "nexa-book"!important;
        }
    }
    .arquitecture{
        .content-info{
            .img-info2{
                @media(max-width:740px){
                    top: 37.6vw;
                }
            }
        }
    }
}
.Information.nou{
    --Yellow:#f9b450;
    --light:#f9b450 ;
    --dark:#14140b;
    --text:#2E2E2D;
    .millenial{
        @media(orientation: landscape){
            height: 26vw;
            .p-top{
                position: relative;
                top: -5vw;
            }
        }
    }
    .arquitecture{
        .content-info{
            .img-info2{
                @media(max-width:740px){
                    top: 34vw;
                }
            }
        }
    }
}
.Information.town{
    --Yellow:#f1605f;
    --light:#f1605f;
    --dark: #233442;
    --text:#2E2E2D;
    .arquitecture{
        .content-info{
            .img-info2{
                @media(max-width:740px){
                    top: 37vw;
                }
            }
        }
    }
}
.Information.salaverry{
    --light:#DCB94D;
    --dark:#292929;
    --text:#2E2E2D;
    .arquitecture{
        .content-info{
            .img-info2{
                @media(max-width:740px){
                    top: 38vw;
                }
            }
        }
    }
}
.Information.trend{
    --Yellow:#973921;
    --dark:#362e2c;
    --light:#973921;
    --text:white;
    .arquitecture{
        .content-info{
            .img-info2{
                @media(max-width:740px){
                    top: 34vw;
                }
            }
        }
    }
}
.Information.unbox{
    --Yellow: #eab280;
    --light:#eab280;
    --text:#292929;
    --dark:#292929;
}
.Information.midway{
    --Yellow: #CCBB9A;
    --light:#CCBB9A;
    --text:#292929;
    --dark:#25313D;
    .content-brochure{
        // display: none!important;
    }
}
.Information.midway ~ .filtro.unity{
    --Secondary: #25313D!important;
    --Yellow:#CCBB9A !important;
    --button:white;
    --hoverbutton:white;
}
.Information.unbox{
    @media(min-width:740px){
        height: 63vw;
    }
    .millenial{
        .data{
            .content-brochure{
                // display: none;
            }
        } 
    } 
    .arquitecture{
        .content-info{
            .content-text{
                height: 17.5vw;
                @media(max-width:740px){
                    height: 75.5vw;
                }
            }
            .img-info2{
                @media(max-width:740px){
                    top: 30vw;
                }
            }
        }
    }
}

.Information{
    width: 100%;
    height: 57vw;
    .showMovil{
        display: none!important;;
    }
    .millenial,.arquitecture{
        width: 100%;
        height: auto;
        display: flex;
        padding-top: 6.5vw;
        .titles{
            font-size: 1.8vw;
            padding-bottom: 1vw;
            font-family: "nexa-book";
            color:var(--letter);
            .bold{
                font-family: "nexa-bold";
                color:var(--letter);
            }
        }
        .text_content{
            width: 47.6%;
            padding-left: 9.6vw;
            padding-right: 3.5vw;
            
            .paragraph{
                font-family: "nexa-book";
                text-align: justify;
                word-spacing: 0vw;
                font-size: 1.24vw;
                color:var(--letter);
                .space{
                    padding: .5vw 0;
                }
            }
        }
        .span-1{
            border-right: 0.08vw solid black
        }
        .data{
            width: 52.4%;
            padding-left: 3.75vw;
            .send{
                color: var(--letter);
                height: 2.1vw;
                width: 20.5vw;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                // border: 0.08vw solid black;
                margin-bottom: 1.8vw;
                font-size: 1.3vw;
                font-weight: bold;
                font-family: "nexa-heavy";
                .etapa{
                    font-family: "nexa-bold";
                    font-size: 1.3vw;
                    font-weight: 100;
                }
            }
            .flex-row{
                margin-bottom: 2.1vw;
            }
           
            .attributes{
                display: flex;
                flex-direction: column;
                color: var(--letter);
                .title-attr{
                    font-family: "nexa-heavy";
                    font-size: 1.3vw;
                    padding-right: 3.2vw;
                }
                .info-attr{
                    font-family: "nexa-book";
                    font-size: 1.3vw;
                    white-space: nowrap;
                }
                .plus{
                    width: 14vw;
                    display: flex;
                    flex-direction: column;
                }
            }
            #ubicacion-desktop{
                width: 17.5vw;
            }
            .content-brochure{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 32.9vw;
                margin-top: 2vw;
                .brochure{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 4vw;
                    width: 100%;
                    // border: 2px solid black;
                    font-size: 1.3vw;
                    background: var(--light);
                    color: var(--text);
                    transition: .3s;
                }
                .brochure:hover{
                    background: var(--dark);
                    color: white;

                }
            }
            #ubicacion{
                display: none;
            }
        }
    }
    .arquitecture{
        padding-left: 9.6vw;
        flex-direction: column;
        .content-info{
            width: 100%;
            height: 25vw;
            padding-right: 5.5vw;
            position: relative;
            top: -3vw;
            .img-info1{
                top: 4.5vw;
                width: 35vw;
                position: absolute;
                height: 24.5vw;
            }
            .img-info2{
                position: absolute;
                left: 37vw;
                width: 20vw;
                top: 4.6vw;
                height: 24.5vw;
            }
            .content-text{
                width: 42vw;
                height: 18.5vw;
                float: right;
                border: 3px solid;
                border-color : var(--Yellow);
                background: white;
                .texto{
                    font-family: "nexa-book";
                    width: 59%;
                    height: 100%;
                    font-size: 1.24vw;
                    float: right;
                    padding-top: 4.4vw;
                    padding-right: 3vw;
                    word-spacing: 0.1vw;
                    line-height: 1.617vw;
                    color: var(--letter);
                    .bold{
                        font-family: "nexa-bold";
                    }
                    .span{
                        padding: 0.7vw 0;
                    }
                }
            }

        }
    }
}
@media (orientation:portrait), (max-width:700px){
    $paragraph: 3.5vw;
    
    .Information{
        height: auto;
        .showMovil{
            display: flex!important;;
        }
        .millenial{
            padding-top: 8.5vw;
            flex-direction: column;
            .text_content,.data{
                width: 100%;
                padding: 0 8vw;
            }
            .text_content{
                .titles{
                    text-align: center;
                    font-size: $title;
                    padding-bottom: 4vw;
                }
                .paragraph{               
                    font-size: $paragraph;
                    padding-bottom: 2vw;
                }
            }
            .span-1{
                display: none;
            }
            .data{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-bottom: 6vw;
                .show{
                    display: none;
                }
                .send{
                    height: 7.1vw;
                    width: auto;
                    padding: 0 3vw;
                    font-size: 3.5vw;
                    margin: 4.8vw 0;
                    border: .08vw solid #000;
                    justify-content: center;
                    .etapa{
                        font-size: 3.5vw;
                    }
                } 
                .flex-row{
                    flex-wrap: wrap;
                }
                .attributes{
                    width: 100%;
                    
                    .title-attr,.info-attr{
                        font-size: $paragraph;
                        padding-right: 0!important;
                        // padding-right: 4.2vw;
                        margin-bottom: 3vw;
                    }
                }
                #ubicacion-desktop{
                    display: none;
                }
                .content-brochure{   
                    width: 60.9vw;
                    .brochure{
                        width: 100%;
                        height: 8vw;
                        font-size: 3.3vw;
                    }
                }
                #ubicacion{
                    display: flex;
                    width: 35vw;
                }
            }
        }
        .arquitecture{
            padding: 6vw 8vw 4vw 8vw;
            .titles{
                font-size: $title;
                text-align: center;
            }
            .content-info{
                height: auto;
                top: 0;
                .img-info1{
                    display: none;
                }
                .img-info2{
                    position: absolute;
                    left: 12.5vw;
                    width: 66vw;
                    top: 33.6vw;
                    z-index: 5;
                    height: auto;
                }
                .content-text{
                    width: 66vw;
                    height: 71.5vw;
                    float: left;
                    margin-top: 4vw;
                    margin-bottom: 44vmin;
                    position: relative;
                    left: 6vw;
                    .texto{
                        font-size: 3.5vw;
                        line-height: 3.6vw;
                        line-height: 3.3vw;
                        padding-top: 6vw;
                        padding-left: 6vw;
                        padding-right: 6vw;
                        width: 100%;
                    }
                }
            }
        }
    }
}