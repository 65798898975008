.calidad{
    width: 100%;
    *p {
        margin-bottom: 0;
    }
    .certificacion{
        height: 28.6vw;
        width: 100%;
        background:linear-gradient(90deg, rgba(28,226,173,0) 47%, rgba(245,245,245,1) 54%, rgba(245,245,245,1) 58%, rgba(245,245,245,1) 65%, rgba(245,245,245,1) 100%)
        ,url("../../Assets/images/certificacion/familia.jpg");
        background-repeat: no-repeat;
        background-size: contain;
        overflow: hidden;
        .description{
            width: 58%;
            height: 100%;
            position: relative;
            float: right;
            .brand-svg{
                height: 11.8vw;
                position: relative;
                svg{
                    height: 6.5vw;
                    bottom: 0;
                    top: 1.2vw;
                    right: 0;
                    left: 0;
                    margin: auto;
                    position: absolute;
                }
            }
            .content-paragraph{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                line-height: 2vw;
            }
            .paragraph{
                font-family: "nexa-heavy";
                font-size: 1.8vw;
                color: var(--Secondary);
                text-align: center;
                width: 45vw;
               
            }
            .paragraph1{
                text-align: center;
                font-size: 1.1vw;
                letter-spacing: .5px;
                margin: .5vw;
            }
            .know-more{
                position: absolute;
                width: 11vw;
                height: 3.2vw;
                display: flex;
                align-items: center;
                justify-content: center;
                bottom: 3.9vw;
                right: 0;
                left: 0;
                margin: auto;
                color: white;
                background: var(--Primary);
                transition: .2s linear;
                cursor: pointer;
                font-size: 1.1vw;
                letter-spacing: .1vw;
            }
        }
    }
    .desarrollo_sostenible{
        height: 23.4vw;
        width: 100%;
        padding: 0 3.7vw;
        background: black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        .right{
            background: url("../../Assets/images/certificacion/hojas-right.png");
            background-repeat: no-repeat;
            background-size: 3.5vw;
            background-position: 86% 35%;
            float: right;
            padding-right: 6.5vw;
            width: 38%;
            .paragraph-2{
                text-align: center;
                padding-top: 1.5vw;
                width: 27vw;
                margin-bottom: .9vw;
                word-spacing: .1vw;
                letter-spacing: 0.02vw!important;
            }
            .companies{
                height: 8.1vw;
                img{
                    height: 100%;
                    right: -0.3vw;
                    position: relative;
                }
            }
        }
        .left{
            background: url("../../Assets/images/certificacion/hojas-left.png");
            background-repeat: no-repeat;
            background-size: 4vw;
            background-position: 0% 19%;
            padding-left: 4.7vw;
            width: 40%;
        }
        .left,.right{
            height: 100%;
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-bottom: 1vw;
            
            .paragraph{
                font-size: 1.8vw;
                font-family: "nexa-heavy";
                margin-bottom: 0;
                .Fourth{
                    font-family: "nexa-heavy";
                    font-size: 1.8vw;
                    display: inline-block;
                    margin-bottom: 0;
                }
            }
            .paragraph-2{
                font-size: 0.98vw;
                font-family: "nexa-book";
                letter-spacing: .1vw;
                .d-block{
                    font-family: "nexa-book";
                    margin-bottom: 0;
                }
            }
            .barra{
                height: .3vw;
                width: 3.5vw;
                background: var(--Fourth);
                margin:1vw  auto 1.2vw 0;
                border-radius: .05vw;
            }
        }
        .center{
            height: 100%;
            width: 23%;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 0.79vw;
            position: relative;
            img{width: auto;height:100%;}
        }
    }
}
@media (orientation:portrait), (max-width:700px){
    .calidad{
        .certificacion{
            height: auto;
            background-position: -204% 0%;
            .description{
                .brand-svg{
                    height: 12.8vw;
                }
                .content-paragraph{
                    padding: 2vw;
                    line-height: 3.1vw;
                    .paragraph{
                        font-size: 2.5vw;
                        width: 49vw;
                    }
                    .paragraph1{
                        padding: 1vw;
                        font-size: 2vw;
                        width: 41vw;
                    }
                }
                .know-more{
                    position: relative;
                    bottom: 0;
                    margin-bottom: 3vw;
                    user-select: none;
                    width: 33vw;
                    height: 10.3vw;
                    font-size: 4vw;
                }
            }
        }
    }
    .desarrollo_sostenible{
        height: auto!important;
        flex-direction: column;
        padding: 8vw 0!important;
        .left,.right{
            width: 76%!important;
        }
        .left{
            background-size: 5.5vw !important;
            background-position: 0% 49% !important;
            padding: 0 2vw!important;
            .paragraph{
                font-size: 6vw!important;
                text-align: center;
                .Fourth{
                    font-size: 6vw!important;
                }
            }
            .barra{
                position: relative;
                margin: 2vw auto 3vw auto!important;
                height: 0.4vw!important;
                width: 6.5vw!important;
            }
        }
        .paragraph-2{
            text-align: center;
            font-size: 2.4vw!important;
        }
        .right{
            background-size: 6vw!important;
            background-position: 97% 26%!important;
            padding: 0!important;
            .paragraph-2{
                width: 89%!important;
            }
            .companies{
                height: 20.1vw!important;
                margin: 4vw 0 0 0;
            }
        }
        .center{    
            width: 32%!important;
            left: 2.79vw!important;
            
            img{
                height: auto!important;
                width: 100%!important;
            }
        }
    }
}