.icon-right{
    mask-image: url("../../Assets/iconos/icon-right.svg");
}
.contacto.inicio{
    margin-top: 2rem;
    .paragraph{
        font-size: 1.1rem;
    }
    .formulario{
        @media(max-width:740px){
            padding-left: 5vw;
            padding-right: 5vw;
            margin-bottom: 3rem;
        }
        .flex-column-form{
            width: 49%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            @media(max-width:1000px){
                width: 100%;
            }
            option{
                color: black;
            }
            input,select{
                @media(max-width:740px){    
                    width: 100%;
                }
            }
        }
    }
}
.benefits,.benefits{
    .SliderDepartamentos{
        height: 19rem;
        @media(min-width:1400px){
            height: 23rem;
        }
        @media(max-width:1100px){
            margin-top: 2rem;
            transform: translateY(3rem);
            height: 19rem;
        }
        @media(max-width:1100px){
            margin-top: 0rem;
        }
    }
}
.page-terminos{
    .SliderDepartamentos{
        @media(max-width:600px){
            height: 13rem;
        }
        .background::after{
            content: "";
            position: absolute;
            z-index: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.397);
        }
    }
}
.benefits,.page-terminos,.Politics{
    .SliderDepartamentos{
        height: 20rem;
        
        .title-background{
            position: absolute;
            color: white;
            font-size: 1.7rem;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            font-family: "nexa-bold";
            @media(min-width:1400px){
                font-size: 2rem;
            }
            @media(max-width:740px){
                padding: 0px 1rem;
            }
        }
    }
}
.benefits{
    .cards{
        --height:14rem;
        position: relative;
        height: 47rem;
        @media(min-width:1400px){
            height: 51rem;
            --height:16rem;
        }
        @media(max-width:1000px){
            height: auto;
        }
       
        .content-cards{
            display: flex;
            flex-wrap: wrap;
            width: 60rem;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin-bottom: 4rem;
            @media(min-width:1400px){
                width: 70rem;
            }
            @media(max-width:1000px){
                width: 50rem;
            }
            @media(max-width:1000px){
                width: 100%;
                height: auto;
                position: relative;
            }
        }
        .card{
            width: 23rem;
            height: var(--height);
            border-radius: 1.5rem;
            overflow: hidden;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.185);
            display: flex;
            flex-direction: column;
            margin: 0 1rem;margin-bottom: 2rem;
            transition: .5s;
            @media(min-width:1400px){
                width: 29rem;
            }
            @media(max-width:400px){
                width: 90vw;
                border-radius: 1rem;
            }
            .content-img{
                width: 100%;
                height: calc(var(--height) - 5rem);
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: scale(1);
                    transition: 0.5s;
                    z-index: 0;
                }
            }
            .content-text{
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 1.2rem;
                transition: .5s;
                z-index: 1;
                height: 5rem;
                color: black;
                span{
                    font-family: "nexa-bold";
                    font-size: 1rem;
                }
                .mask{
                    background-color: black;
                    height: 1rem;
                    width: 1.3rem;
                }
            }
        }
        .card:hover{
            .content-img{
                img{
                    transform: scale(1.1);
                }
            }
            .content-text{
                background-color: black;
                color: white;
                .mask{
                    background-color: white;
                }
            }
        }
    }
}