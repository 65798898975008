@mixin flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.signal-prev{
    mask-image: url("../../Assets/iconos/areas/signal-prev.svg");
}
.signal-next{
    mask-image: url("../../Assets/iconos/areas/signal-next.svg");
}
.areas{
    background-image:url("../../Assets/images/iconos/background-areas.png");
    background-repeat: no-repeat;
    background-size: 40vw 40vw;
    background-position: 111% 10%;
    background-color: #eaeaea;
    padding-bottom: 4rem;
    padding-top: 9rem;
    overflow: hidden;
    @media(max-width:1200px){
        padding-top: 4rem;
    }
    @media(max-width:1100px){
        overflow: visible;
    }
    @media(min-width:1400px){
        padding-bottom: 7rem;
    }
    @media(min-width:1600px){
        padding-bottom: 4rem;
    }
    
    
    @media(max-width:500px){
        padding-bottom: 40vw;
    }
    *{
        font-family: "nexa-book";
        color: var(--letter);
    }
    * h4 {color: white!important;}
    .d-none{
        display: none!important;
    }
    .change-row{
        margin-bottom: 2rem;
        @media(max-width:1100px){
            align-items: center;
            justify-content: center;
        }
        @media(max-width:600px){
            justify-content: space-around;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        @media(max-width:500px){
            justify-content: center;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0;
        }
    }
    .content-icons{
        display: flex;
        margin-bottom: 3rem;
        margin-top: 3rem;
        @media(max-width:1100px){
            align-items: center;
            justify-content: center;
        }
        @media(max-width:740px){ 
            width: 100%;
            margin-bottom: 2rem;
            margin-top: 2rem;
        }
        @media(max-width:500px){
            margin-bottom: 5vw;
            margin-top: 0vw;
        }
        .icon-comunes,.icon-interiores{
            display: flex;
            position: absolute;
            visibility: hidden;
            z-index: 1;
            .item-scroll{
                display: flex;
            }
            @media(max-width:740px){
                width: 100vw;
            }
            
            @media(max-width:500px){
                .item-scroll{
                    display: flex;
                    padding: 5vw 0;
                }
                .scroll{
                    overflow: scroll;
                    width: 80vw;
                    max-width: 80vw;
                }
            }
            .btn{
                @media(max-width:500px){
                    width: 12vw;
                    height: 12vw;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .mask{
                        background: black;
                        width: 50%;
                        height: 50%;
                    }
                }
            }
            .btn-next{
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
            .btn-prev{
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }
        .icon-comunes.active,.icon-interiores.active{
            visibility: visible;
            position: relative;
            z-index: 2;
            @media(max-width:740px){
                width: 100vw;
                overflow: scroll;
                align-items: center;
                justify-content: center;
            }
        }
        .icon{
            height: 4rem;
            width: 4rem;
            margin-right: 2rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            @media(min-width:1400px){
                height: 5rem;
                width: 5rem;
            }
            @media(max-width:1000px){
                height: 3rem;
                width: 3rem;
                min-width: 3rem;
            }
            @media(max-width:1000px){
                margin:0 .4rem;
            }
            .mask{
                width: 100%;
                height: 100%;
                background: #919191;
            }
            
        }
        .icon.active{
            .mask{
                background: var(--red); 
            }
        }
    }
    .controlador{
        padding-left: 9.4vw;
        @media(max-width:1100px){
            padding-left: 0vw;
            padding-top: 4rem;
            .title{
                text-align: center;
            }
        }
        .none{
            display: none;
        }
        @keyframes show{
            0%{
                opacity: 1;
                filter: blur(0px);
                display: inline-flex;
            }
            99%{
                filter: blur(2px);
                opacity: 0;
            }
            100%{
                z-index: -1;
                height: 0;
                width: 100%;
            }
        }
        .change-row-4{
            @media(max-width:680px){
                flex-wrap: wrap;
                justify-content: center;
                .btn{
                    width: 41.5vw;
                    margin-bottom: 3vw;
                }
                #btnArea4,#btnArea3{
                    margin-top: 2vw;
                }
            }
               
        }
        .btn-iframe{
            // @media(max-width:850px){
            //     margin-right: 0!important;
            // }
        }
        .btn-avance-de-obra{
            @media(max-width:850px){
                margin-top: 1rem;
            }
        }
        
        .btn-radio{
            color: #919191;
            background-color: #eaeaea;
            width: 10rem;
            margin-right: 1.5rem;
            border: 1px solid #919191;
            @media(min-width:1400px){
                width: 12rem;
                font-size: .9rem;
            }
            @media(max-width:1200px){
                font-size: .8rem;
                width: 9rem;
                margin-bottom: 1rem;
            }
            @media(max-width:600px){
                width: 29vw;
                height: 11vw;
                font-size: 3.6vw;
                margin: 0 1vw;
                font-weight: bold;
            }
        }
        .btn-radio.active{
            background-color: var(--red);
            color: white;
            border: 1px solid var(--red);
        }
        .change-row-avance-none{
            .btnArea4{
                display: none;
            }
        }
        h2{
            color: var(--dark);
            margin-bottom: 2rem;
            .bold{
                font-family: "nexa-bold";
                color: var(--title);
            }
        }
    }
    .content-scroll{
        justify-content: flex-start;
        .scroll{
            height: 11vw;
            position: relative;
        }
        .btn-left,.btn-right{
            display: none;
        }
    }
    
    .content-buttons.centrar{
        display: flex;
        align-items: center;
        width: 100%!important;
        .newIcon{
            @media(max-width:740px){
                margin: 0 1vw;
            }
        }
        .newIcon:hover{
            opacity: .5;
        }
    }
    .content-buttons{
        height: 11vw;
        width: auto;
        align-items: center;
        display: inline-flex;
        position: absolute;
        .newIcon,.icon{
            height: 5.4vw;
            width: 5.4vw;
            min-width: 5.4vw;
            margin-right: 1.2vw;
            top: 0;
            bottom: 0;
            margin: auto;
            cursor: pointer;
            opacity: .6;
            svg{
                pointer-events: none;
                height: 2.4vw;
                width: 3.1vw;
                    .icono-1{
                        fill: var(--inactive);
                    }
            }
            svg:hover{
                .icono-1{
                    fill: var(--border);
                }
            }
        }
        .newIcon:hover,.icon:hover{
            opacity: 1;
        }
        .newIcon.active,.icon.active{
            opacity: 1;
            border: 2px solid;
            border-color: var(--Yellow)!important;
            .icono-1{
                fill: var(--Yellow)!important;
            }
        }
    }
    .content-slider{
        width: 89.35vw;
        position: relative;
        @media(max-width:1100px){
            width: 100%;
            .slider,.expand-img{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .areas-comunes,.interiores,.recorrido,.avance-de-obra,.exteriores,.end_project,.depa_piloto{
            display:none;
            width: 89.5vw;
            height: 29vw!important;
            @media(max-width:1100px){
                width: 100vw;
            }
            @media(max-width:600px){
                height: 89vw!important;
            }
            .owl-slider{
                width: 100%;
                @media(max-width:740px){
                    overflow: hidden;
                }
            }
            .slick-slider,.slick-list,.slick-slide,.slick-track,.slick-slide > div{
                height: 100%;
            }
        }
        .avance-de-obra,.exteriores{
            @media(max-width:500px){
                transform: translateY(2rem);
            }
        }
        .end_project{
            .slider img{
                object-position: center!important;
            }
        }
        .virtual-frame,.end_project,.depa_piloto{
            display: none;
        }
        .areas-comunes.active,.interiores.active,.recorrido.active,.virtual-frame.active,.avance-de-obra.active,.exteriores.active,.end_project.active,.depa_piloto.active{
            display: flex;
        }
        .icon-signal{
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            @media(max-width:550px){
                position: initial;
            }
            .btn-prev,.btn-next{
                width: 2rem;
                height: 2rem;
                background: rgba(0, 0, 0, 0.521);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                @media(min-width:1400px){     
                    width: 3.5rem;
                    height: 3.5rem;
                }
                .mask{
                    background: white;
                    width: 40%;
                    height: 40%;
                }
            }
            .btn-prev{
                @media(max-width:550px){
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
            .btn-next{
                @media(max-width:550px){
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
            .btn-next{
                // position: absolute;
                // right: 0;
            }
        }
        .slider{
            height: 100%;
            width: 65vw!important;
            @media(max-width:1000px){
                width: 65vw!important;
                display: flex;
                align-items: center;
                justify-content: center;
                picture{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            @media(max-width:600px){
                width: 100vw!important;
                picture{
                    width: 100%;
                    height: 100%;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                 }
            }
            img{
                width: 80%;
                height: 100%;
                object-fit: cover;
                object-position: bottom;
            }
        }

        .info-slider{
            width: 22rem;
            height: auto;
            background: var(--dark);
            position: absolute;
            top: 7.3vw;
            left: 43vw;
            padding: 2rem 2rem;
            @media(max-width:1100px){
                top: auto;
                left: 0;
                right: 0;
                margin: auto;
                bottom: -51%;
            }
            @media(max-width:500px){
                width: 80vw;
                bottom: auto;
                top: 88%;
                padding: 6vw;
            }
            .sky{
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                margin-bottom: 1rem;
                @media(max-width:500px){
                    justify-content: center;
                    padding-right: 10vw;
                }
                .mask{
                    background: white;
                    width: 3.5rem;
                    height: 3.5rem;
                    margin-right: 1.3rem;
                    @media(max-width:500px){
                        width: 12vw;
                        height: 10vw;
                        position: relative;
                        top: -1.5vw;
                    }
                }
                .title{
                    font-family: "nexa-heavy";
                    color: white;
                    font-size: 1.4rem;
                    @media(max-width:500px){
                        font-size: 4.5vw;
                    }
                }
            }
            .paragraph{
                font-size: 1rem;
                display: block;
                color: white;
                margin-bottom: 0;
                @media(max-width:500px){
                    font-size: 4vw;
                }
            }
            --width:17rem;
            &.avance{
                width: var(--width);
                height: 11rem;
                top: 0vw;
                bottom: 0;
                left: 44.5vw;
                margin: auto;
                @media(max-width:1100px){
                    left: 0;
                    top: auto;
                    height: 5.5rem;
                    z-index: 3;
                    padding: 0;
                }
                .sky{
                    display: flex;
                    flex-direction: column;
                    @media(max-width:1100px){
                        padding: 2rem 2rem;
                        background-color: black;
                    }
                    .icon{
                        margin-bottom: 1rem;
                    }
                    .title{
                        font-size: 1.2rem;
                        text-align: center;
                    }
                }
            }
        }
    }
    .virtual-frame {
        width: 50vw;
        height: 35vw;
        display: flex;
        align-items: center;
        justify-content: center;

        @media(max-width:1100px){
            left: auto;
            right: auto;
            margin: auto;
        }
        @media(max-width:500px){
            width: 100vw;
            height: 100vw;
            margin-top: 12vw;
        }
        .frame{
            width: 100%;
            height: 100%;
            iframe{
                height: 100%;
                width: 100%;
            }
        }
    }
    .counter{
        position: relative;
        margin-top: 1vw;
        color: white;
        width: 3vw;
        justify-content: space-between;
        .change,.static{
            color: white;
            display: block;
            font-size: 1.2vw;
        }
        #middle{
            color: white;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            display: block;
            width: .5vw;
            font-size: 1.15vw;
        }
    }
}
@media(max-width:500px){
    .areas.print{
        padding-bottom: 60vw;
    }
}
.newIcon:hover,.icon:hover{
    opacity: 1;
}
@media (orientation:portrait), (max-width:700px){
    $title:4.5vw;
    .areas{
        height: auto;
        padding-top: 0;
    }
    .counter{
        align-self: flex-start;
        margin-left: 4vw;
        margin-top: 4vw!important;
        width: 10vw!important;
        .change,.static{
            font-size: 4.2vw!important;
        }
        #middle{
            color: white!important;
            font-size: 4.2vw!important;
            width: 2.1vw!important;;
        }
    }
}
.ocultar{
    display: none!important;;
}