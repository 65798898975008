:root{
    --Primary: #E74636;
    --Secondary: #1B1B1D;
    --Third:#78787E;
    --Fourth: #8CC59B;
    --Yellow: #DCB94D;
    --letter:#2E2E2D;
    --navbar:"hidden";
    --height:600px;
    --dark:#000000;
    --red:#f5102d;
}
.b-secondary{
    background-color: var(--Secondary);
}
.c-white{
    color: white;
}
.c-gray{
    color: #1B1B1D99;
}
.uppercase{
    text-transform: uppercase;
}
.radius{
    border-radius: 100rem;
}
.b-red{
    background-color: var(--red);
}
.c-black{
    color: black;
}
.b-white{
    background-color: white;
}
.shadow{
    box-shadow:  0 0 10px -2px rgba(0, 0, 0, 0.212);
}
.btn-radio{
    height: 2.5rem;
    border-radius: 100rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    padding:0 1.5rem;
    padding-top: .1rem;
    border: 0ch;
    cursor: pointer;
    white-space: nowrap;
    padding-top: .1rem;
    font-weight: bold;
    color: #919191;
    @media(min-width:1200px){
        padding: 0 1rem;
        height: 2.5rem;
    }
    @media(min-width:1400px){
        font-size: 1rem;
        height: 3.1rem;
        padding:0 2rem;
    }
   
    @media(max-width:500px){
        height: 12vw;
    }
}
.mt-1{
    margin-top: 1rem;
}
.line-thought{
    text-decoration: line-through;
}
.mask{
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
}
.container{
    margin: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    @media(min-width:800px){
        max-width: 800px;
    }
    @media(min-width:1000px){
        max-width: 1000px;
    }
    @media(min-width:1200px){
        max-width: 1200px;
    }
    @media(min-width:1400px){
        max-width: 1400px;
    }
    @media(min-width:1600px){
        max-width: 1600px;
    }
    @media(max-width:600px){
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

html{
    scroll-behavior: smooth;
}
#root{
    // overflow: hidden;
}
.lg-autoplay-button,.lg-download {
    display: none!important;
}
.mt-05{
    margin-top: .5vw;
}
.spacing{
    letter-spacing: .3rem;
}
.mask{
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat; 
}
//Sliders
.slick-list{
    overflow: hidden;
}
.slick-track{
    display: flex;
    .slick-slide > div{
        display: flex;
    }
}
.owl-nav{
    display: none;
    .owl-prev,.owl-next{
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 3vw;
        height: 3vw;
        
        svg{
            height: 100%;
        }
    }
    .owl-prev{left: 5%;cursor: pointer;}
    .owl-next{right: 5%;cursor: pointer;}
}
.owl-stage{
    display: flex;
    flex-direction: row;
    height: 100%;
}
.owl-stage-outer{
    overflow: hidden;
    position: relative;
    height: 100%;
    overflow: hidden;
}
.owl-carousel{
    width: 100%;
    height: 100%;
    position: relative;
    font-family: "aldrich";
}
.MyCarouselHorizontal{
    overflow: hidden;
}
// end Slider
.Section{
    width: 100%;
    height: var(--height);
    min-height: 700px;
    position: relative;
}
.flex-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.flex-column{
    display: flex;
    flex-direction: column;
}
.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.text-center{
    text-align: center;
}


.mb-1{
    margin-bottom: 1vw;
}
.mr-1{
    margin-right: .5vw;
}
.lg-thumb-item.active{
    border-color: var(--Primary)!important;
}
.white-space{
    white-space: nowrap;
}
//Colores
.Primary{
    color: var(--Primary);
}
.Secondary{
    color: var(--Secondary);
}
.Fourth{
    color:var(--Fourth)
}
.white{
    color: white;
}

//
.pointer{
    cursor: pointer;
}
// .img-fluid{
    
// }
.relative{
    position: relative;
}

.w-100{
    width: 100%;
}
.h-100{
    height: 100%;
}

.d-block{
    display: block;
}
.d-flex{
    display: flex;
}

// posiciones 
.p-absolute{position: absolute;}
.show{
    animation: 0.7s show forwards;
}
.hide{
    // animation: 0.7s hide forwards;
}
.desktop{
    @media(max-width:740px),(orientation:portrait){
        display: none;
    }
    @media(min-width:740px),(orientation:landscape){
        display: flex;
    }
}
.movil{
    @media(min-width:740px),(orientation:landscape){
        display: none;
    }
    @media(max-width:740px),(orientation:portrait){
        display: block;
    }
}
@keyframes hide{
    0%{left: 0;}
    100%{left: 100%;}
}
@keyframes show{
    0%{left: 100%;}
    100%{left: 0;}
}
