.Loading{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 21;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 3s;
    .logo{
        width: 5rem;
        @media(max-width:740px){
            width: 4.5rem;
        }
    }
}
.Loading.inactive{
    opacity: 0;
    visibility: hidden;
}