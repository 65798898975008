.nosotros.proyectos-entregados{
    margin-top: 4rem;
    padding-top: 3rem;
}
.nosotros{
    margin-top: 5rem;
    // height: 120vh;

    .title{
        // font-size: 1.8vw;
        font-family: "nexa-book";
        color: #2E2E2D;
        text-align: center;
        font-size: 2rem;
        @media(min-width:1400px){
            font-size: 2.3rem;
        }
    }
    .section-info{
        // background: url("../../Assetsnosotros/background-down.png"),#F7F7F7;
        background-position: 100% 100%,100%;
        background-repeat: no-repeat;
        background-size: 100%;

        .content-paragraph{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-bottom: 3vw;
            
            .paragraph{
                width: 53%;
                font-family: "nexa-book";
                color: #2E2E2D;
                font-size: 1.19vw;
                text-align: justify;
                line-height: 1.7vw;
            }

        }
        .content-info{
            margin-bottom: 4vw;
            img{
                width: 100%;
                height: auto;
            }
        }   
    }
   
    .timing{
        position: relative;
        margin-bottom: 6vw;
        .brands{
            margin-right: 0;
            .brand{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                @media(max-width:740px){
                    flex-direction: column;
                }
                .content-item{
                    margin-right: 5rem;
                    @media(max-width:740px){
                        display: flex;
                        margin-right: 0rem;
                        margin-bottom: .5rem;
                    }
                    .two{
                        @media(max-width:500px){
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                        }
                        .item{
                            margin-right: 2rem;
                            @media(max-width:500px){
                                margin-right: 0;
                                object-position: center;
                            }
                        }
                        .item:last-child{
                            margin-right: 0;
                        }
                    }
                    .title{
                        text-align: left;
                        font-size: 1rem;
                        width: 100%;
                        @media(max-width:740px){
                            text-align: center;
                            width: 100%;
                            font-size: 3.19vw;
                        }
                    }
                    
                    .bestplace{
                        height: 2.3rem;
                        object-fit: contain;
                        object-position: left;
                        @media(max-width:740px){
                            display: flex;
                            height: 2rem;
                        }
                        @media(max-width:740px){
                            height: 2.5rem;
                        }
                    }
                    .bestplace-img{
                        transform: scale(.9);
                        @media(max-width:740px){
                            object-position: center;
                        }
                    }
                }
                .content-item:last-child{
                    margin-right: 0;
                }
            }
        }
        .title{
            margin-top: 2vw;
            margin-bottom: 0;
            @media(max-width:740px){
                font-size: 5.8vw;
                width: 90%;
                margin: auto;
                line-height: normal;
            }
        }
        .linea{
            width: 10vw;
            top: 5vw;
            height: 310vw;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            svg{
                height: 100%;
                width: 100%;
            }
            .cls-2{
                display: none!important;
            }
            #circulo{
                display: inherit!important;
            }
        }
        .time{
            margin-bottom: 2vw;
            justify-content: center;
            position: relative;
            .imagen{
                height: 20vw;
                @media(max-width:500px){
                    height: 50vw;
                }
                img{
                    width: 21vw;
                    height: 100%;
                    object-fit: cover;
                    object-position: bottom;
                }
            }
            
            .time-paragraph{
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 27vw;
                margin-left: 5vw;
                padding-left: 5.1vw;
                padding-right: 1.5vw;
                .sub-title{
                    margin-bottom: 1vw;
                    font-size: 1.24vw;
                    color: #E41426;
                    font-weight: bold;
                    
                }
                .ubicacion{
                    color: #2E2E2D;
                    font-size: 1.2vw;
                    font-weight: bold;
                    font-family: "nexa-book";
                }
                .parrafo{
                    font-size: 1vw;
                    font-family: "nexa-book";
                    line-height: 1.4vw;
                    text-align: justify;
                    margin-bottom: 1vw;
                }
                
            }
            .time-left{
                margin-left: 0;
                padding-left: 0;
                padding-right: 5.5vw;
                margin-right: 5.2vw;
            }
            .time-rigth{
                position: relative;
                left: -1vw;
            }
        }
        .time::after{
            content: "";
            width: .8vw;
            height: .8vw;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background: #e41426;
            border-radius: 100%;
            z-index: 12;
            @media(max-width:740px){
                content: "";
                width: 2.5vw;
                height: 2.5vw;
                position: absolute;
                right: auto;
                left: -12.1vw;
            }       
        }
    }
}
.proyectos-entregados{
    .timing{
        .title{
            margin-bottom: 6rem;
        }

    }
}

.counter-1{
    display: none;
}
@media (orientation:portrait), (max-width:700px){
    #circulo{
        transform: scale(0.7);
        transform-origin: 50% 0.6%;
    }
    .nosotros{
        margin-top: 4.5rem;

        .title{
            font-size: 5.8vw;
        }
        .section-info{
            .content-info{
                margin-bottom: 7vw;
            }
            .content-paragraph{
                .paragraph{
                    width: 83%;
                    font-size: 3.19vw;
                    line-height: 4.7vw;
                }
            }
        }
        .timing{
            margin-bottom: 10vw;
            .order{
                .time-paragraph{
                    order: 2;
                }
            }
            .time{
                .time-rigth{
                    left: 0;
                }
                margin-left: 21vw;
                flex-direction: column;
                .imagen{
                   
                    img{
                        width: 59vw;
                    }
                }
                .time-paragraph{
                    width: 59vw;
                    margin-left: 0vw;
                    padding-left: 0;
                    padding-right: 0;
                    margin-bottom: 2vw;
                    .sub-title{
                        width: 60vw;
                        display: flex;
                        justify-content: flex-start;
                        font-size: 4vw;
                        margin: 2vw 0;
                    }
                    .parrafo{
                        font-size: 3vw;
                        line-height: 4vw;
                    }
                    .ubicacion{
                        font-size: 3.6vw;
                    }
                }
            }
            .title{
                font-size: 5.8vw;
                margin-bottom: 8vw;
            }
            .linea{
                top: -1.5vw;
                height: 791vw;
                left: -1vw;
                right: auto;
                width: 23vw;
                display: none;
            }
            .counter-1{
                display: flex;
                position: absolute;
                top: 0;
                height: 1395vw;
                width: 2px;
                max-width: 1px;
                background:rgb(204, 204, 204);
                justify-content: center;
                margin-left: 10vw;
                .ball{
                    height: .5rem;
                    width: .5rem;
                    min-width: 0.7rem;
                    min-height: .7rem;
                    background: red;
                    border-radius: 100%;
                    left: -0.35rem;
                    right: 0;
                    margin: auto;
                    position: absolute;
                    display: none;
                    
                }
                .circulo{
                    display: block;
                    height: 7vw;
                    width: 7vw;
                    left: -3.2vw;
                    top: 0;
                    background: transparent!important;;
                    svg{
                        height: 100%;
                        width: 100%;
                    }
                }
                .id-1{
                    top: 45vw;
                }
                .id-2{
                    top: 151vw;
                }
                .id-3{
                    top: 257vw;
                }
                .id-4{
                    top: 363vw;
                }
                .id-5{
                    top: 472.5vw;
                }
                .id-6{
                    top: 576vw;
                }
                .id-7{
                    top: 688vw;
                }
                .id-8{
                    top: 793vw;
                }
                .id-9{
                    top: 897vw;
                }
                .id-10{
                    top: 1017vw;
                }
                .id-11{
                    top: 1123vw;
                }
                .id-12{
                    top: 1231vw;
                }
                .id-13{
                    top: 1332vw;
                }
            }
        }
    }
}
