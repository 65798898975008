.content-button-contact{
    position: fixed;
    z-index: 8;
    right: 3vw;
    bottom: 2vw;
}
.whatsapp{
    display: flex;
    align-items: center;
    flex-direction: column;
    img{
        width: 5vw;
    }
    .contact{
        cursor: pointer;
        width: 4.5vw;
        margin-bottom: 1vw;
        @media(max-width:740px){
            width: 7.5vw;
        }
        @media(max-width:500px){
            width: 13.5vw;
        }
    }
}
@media (max-width:700px){
    .whatsapp{
        img{
            width: 15vw;
        }
    }
}