.Politics{

    .SliderDepartamentos{
        .title-background{
            z-index: 1;
        }
        &::after{
            content: "";
            position: absolute;
            z-index: 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.692);
        }
    }
}
.Politicas{
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.541);
    z-index: 10000;
    position: fixed;
    display: none;
    align-items: center;
    justify-content: center;
    top: 0;
    &.active{
        display: flex;
    }
    
    .content-politicas{
        width: 37rem;
        height: 20rem;
        background-color: white;
        border-radius: 1rem;
        padding: 1.5rem;
        padding-top: 3rem;
        position: relative;
        @media(max-width:500px){
            width: 95vw;
            padding-left: 1vw;
            padding-right: 1vw;
            height: 114vw;
        }   
        .icon-close{
            width: 1rem;
            height: 1rem;
            background: #636363;
            position: absolute;
            right: 2rem;
            top: 1rem;
            cursor: pointer;
        }
        .content-scroll{
            overflow-y: scroll;
            height: 100%;
            padding: 1rem;
        }
        h2{
            font-size: 1rem;
            text-align: center;
            margin-bottom: 1rem;
            font-weight: bold;
        }
        .sub-title{
            font-size: .8rem;
            font-weight: bold;
            margin-bottom: 1rem;
            display: block;
        }
        .paragraph{
            margin-bottom: 1rem;
            font-size: .8rem;
            text-align: justify;
        }
        .bold{
            font-weight: bold;
        }
        .list{
            padding-left: 1rem;
        }
        .item{
            font-size: .8rem;
            display: flex;
            .line{
                margin-right: .5rem;
            }
        }
    }
}