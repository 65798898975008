.MuiOutlinedInput-notchedOutline{
    border: 0!important;
    border-bottom: 2px solid black!important;
    border-radius: 0!important;
}
.MuiList-root{
    .MuiMenuItem-root{
        padding: .5rem 1rem!important;
    }
}
.page-post-venta{
    .MuiFormControl-root{
        width: 30rem;
    }
    
    .title-background{
        position: relative!important;
        height: min-content;
    }
    .content-title-page-post-venta{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        top: 0;
        flex-direction: column;
        .paragraph{
            text-align: center;
        }
    }
    .background-shadow{
        span{
            position: relative;
            top: 0;
        }
    }
    .title-select-search{
        font-size: 2rem;
        text-align: center;
        display: flex;
        align-items: center;
        margin: auto;
        margin-bottom: 2rem;
        @media(max-width:500px){
            margin-bottom: 0rem;
            font-size: 1.5rem;
        }
    }
}
.select-search{
    padding: 6rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .paragraph{
        padding: 0 1rem;
        font-size: 1.3rem;
        margin-bottom: 4rem;
        @media(max-width:1400px){
            font-size: 1.2rem;
        }
        @media(max-width:500px){
            font-size: 1.1rem;
        }
    }
}
.content-select-search{
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width:1000px){
        flex-direction: column;
    }
    .btn{
        height: 3.5rem;
        background-color: var(--red);
        color:white;
        font-size: 1.3rem;
        border: 0;
        border-radius: 40rem;
        padding: 0 2rem;
        margin-left: 1rem;
        cursor: pointer;
        @media(max-width:1000px){
            margin-top: 1rem;
        }
        @media(max-width:500px){
            height: 2.5rem;
            font-size: 1rem;
        }
    }
    .select{
        margin: 0;
        width: 30rem;
        height: 3.5rem;
        background-color: white;
        color: black;
        border-bottom: 2px solid black;
        padding-top: .7rem;
        padding-left: 1rem;
        font-size: 1.3rem;
        -webkit-appearance: none;
        -o-appearance: none;
        appearance: none;
        background-image: url("../../Assets/iconos/down-svg.svg");
        background-position: calc(100% - 1rem) 40%;
        background-repeat: no-repeat;
        background-size: 1.7rem;
        @media(max-width:500px){
            width: 15rem;
            height: 2.5rem;
            font-size: 1rem;
            background-size: 1.2rem;
            background-position: calc(100% - 1rem) 60%;
            border-bottom: 1px solid black;
        }
    }
    
}