.linkdin{
    mask-image: url("../../Assets/images/iconos/linkdin.svg");
}
.facebook{
    mask-image: url("../../Assets/images/iconos/facebook.svg");
}
.instagram{
    mask-image: url("../../Assets/images/iconos/instagram.svg");
}
.cart-buy{
    mask-image: url("../../Assets/iconos/cart.svg");
}

.navbar{
    width: 100%;
    height: auto;
    background-color: var(--dark);
    position: fixed;
    top: 0;
    z-index: 20;
    transition: .5s;
    .container{
        height: 100%;
        display: flex;
    }
    .content-nav{
        height: 5rem;
        position: relative;
        align-items: center;
        @media(max-width:500px){
            height: 4.5rem;
        }
        .toggle{
            cursor: pointer;
            padding: 1rem 0;
            margin-left: auto;
            transition: .5s;
            z-index: 3;
            // pointer-events: none;
            .line{
                transition: .5s;
                width: 2rem;
                height: 3px;
                border-radius: 100rem;
                background: white;
            }
            .line-2{
                transition: .5s;
                margin-top: .3rem;
            }
        }
        .toggle.active{
            .line{
                background: var(--gray);
                @media(max-width:1200px){
                    background: white;
                }
            }
            .line-1{
                transform: rotate(45deg);
            }
            .line-2{
                margin-top: -3px;
                transform: rotate(-45deg);
            }
        }
        @keyframes toggleLine1{
            0%{

            }
            100%{

            }
        }
    }
    .barra{
        height: 70%;
        width: 1px;
        background: white;
        margin: auto 1rem;
    }
    .more{
        height: 3rem;
        background-color: var(--primary);
        overflow: hidden;
        .container{
            display: flex;
            align-items: center;
            .sub-title{
                font-size: 0.8rem;
                @media(min-width:1400px){
                    font-size: 1rem;
                }
                @media(max-width:740px){
                    font-size: .7rem;
                    width: 50%;
                }
            }
            .sub-title-1,.barra{
                @media(max-width:800px){
                    display: none;
                }
            }
            .btn-know{
                padding: .5rem 1rem;
                border-radius: 100rem;
                margin-left: 1rem;
                font-weight: bold;
                box-shadow: 0px 0px 10px 0px #00000033;
                @media(min-width:1100px){
                    font-size: .8rem;
                }
                @media(min-width:1400px){
                    font-size: 1rem;
                }
                @media(max-width:740px){
                    white-space: nowrap;
                    font-size: .7rem;
                    margin-left: .5rem;
                    margin-right: auto;
                }
            }
            .button{
                width: 1.8rem;
                height: 1.8rem;
                margin-left: auto;
                background-color: transparent;
                border: 1px solid white;
                padding-left: 1px;
                @media(max-width:740px){
                    padding-left: 0px;
                    min-width: 1.8rem;
                    margin-left: 1rem;
                }
                .add{
                    background: white;
                    width: 50%;
                    height: 50%;
                    mask-position: center;
                    transform: rotate(45deg);
                }
            }
        }
    }
    .more.close{
        transition: .2s;
        height: 0px;
       
    }
    .container-link{
        margin-left: 0;
        display: flex;
        align-items: center;
        margin-left: auto;
        height: 100%;
        width: 100%;
        transition: .3s;
        @media(max-width:1200px){
            position: fixed;
            top: 0;
            background: black;
            width: 100%;
            height: 0vh;
            overflow-y: scroll;
        }
        .links{
            width: 100%;
            display: flex;
            align-items: center;
            height: 100%;
            
            @media(max-width:1200px){
                flex-direction: column;
                align-items: center;
                justify-content: center;
                min-height: 650px;
                overflow: hidden;
                position: absolute;
                top: 0;
            }
            @media(max-width:800px){
                align-items: flex-start;
                width: 86%;
                margin: auto;
                left: 0;
                right: 0;
            }
            @media(max-width:600px){
                overflow: initial;
                padding-top: 6rem;
                height: auto;
                width: 100%;
                padding-bottom: 0;
            }
            .center{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                @media(max-width:1200px){
                    flex-direction: column;
                }
            }
        }
        
        .title-menu{
            @media(min-width:1200px){
                display: none!important;
            }
            @media(max-width:1200px){
                display: flex!important;
                .text-link{
                    color: var(--gray)!important;
                    opacity: .3;
                    font-size: 2.5rem!important;
                    font-family: "gotham-bold";
                    margin-bottom: 1.5rem;
                }
            }
        }
        .btn-cart{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: .5rem 1rem;
            padding-bottom: .4rem;
        }
        .link{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;
            height: 2.5rem;
            transition: .3s;
            padding: 1rem;
            width: auto;
            position: relative;
            height: 100%;
            @media(min-width:1400px){
                padding: 1rem;
            }
            @media(max-width:1200px){
                color: var(--primary);
                height: auto;
            }
            @media(max-width:600px){
                padding-left: 1rem;
            }
            @media(max-width:500px){
                justify-content: center;
                align-items: flex-start;
                padding-left: 2.5rem;
                padding: .8rem 0;
                padding-left: 2.5rem;
            }
            
            .content-text{
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
            }
          
            .text-link{
                // padding-bottom: 0.2rem;
                color:#f9f9f9;
                display: flex;
                @media(min-width:1200px){
                    font-size: .8rem;
                    white-space: nowrap;
                }
                @media(min-width:1600px){
                    font-size: 1rem;
                }
                @media(max-width:1200px){
                    font-size: 1.5rem;
                }
                @media(max-width:600px){
                    font-size: 1.3rem;
                }
                @media(max-width:500px){
                    font-size: 1.1rem;
                }
                // line-height: 0.9rem;
                
            }
            .barra-line{
                width: 0%;
                display: block;
                height: .7vw;
                border-radius: 100rem;
                position: absolute;
                bottom: -.5rem;
                background: var(--primary);
                transition: .4s;
                @media(max-width:1200px){
                    display: none;
                }
            }
            .cart{
                path{
                    stroke: white!important;
                }
            }
            .cart > div{
                width: 1.2rem!important;
                height: 1.2rem!important;
                margin-right: .5rem!important;
                svg{
                    transform: translate3d(0px, 0px, 0px) scale(.9)!important;
                }
            }
            .line{
                display: block;
                width: 0%;
                height: 2px;
                border-radius: 100rem;
                background: white;
                margin-top: .4rem;
                transition: .4s;
                
            }
            .content-link-hover{
                position: absolute;
                top: 5rem;
                left: 0;
                background: black;
                display: none;
                .projects-sell,.projects-ended{
                    color: white;
                    white-space: nowrap;
                    padding: 1rem 1rem;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-weight: 100;
                    .signal-right{
                        mask-image: url("../../Assets/iconos/signal-right-nav.svg");
                        mask-repeat: no-repeat;
                        mask-size: contain;
                        mask-position: center;
                        width: .9rem;
                        height: .9rem;
                        background-color: white;
                        margin-left: 1rem;
                    }
                    .content-projects{
                        position: absolute;
                        left: 100%;
                        top: 0;
                        background: black;
                        display: none;
                        .project{
                            padding: 1rem 1rem;
                            color: white;
                        }
                    }
                }
                .projects-ended:hover,.projects-sell:hover,.project:hover{
                    color: white;
                    background-color: var(--red);                    
                }
                .projects-ended:hover,.projects-sell:hover{
                    .content-projects{
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }
        .link:hover{
            font-weight: bold;
            .text-link{
                font-weight: bold;
            }
            .content-link-hover{
                display: flex;
                flex-direction: column;
            }
        }
        .link-buttons{
            display: flex;
            height: 2rem;
            align-items: center;
            @media(max-width:1200px){
                flex-direction: row;
                padding-bottom: 2rem;
            }
            @media(max-width:800px){
                align-items: flex-end;
                justify-content: center;
                width: 100%;
            }
            @media(max-width:500px){
                margin-top: auto;
            }
            .depa{
                @media(min-width:600px){
                    .img-depa{
                        display: none;
                    }
                    .sub-text-link{
                        display: none;
                    }
                }
                @media(max-width:600px){
                    width: 100%!important;
                    background: radial-gradient(circle, #0067a4 0%, #0080cc 0%, #0067a4 100%)!important;
                    color: white!important;
                    padding: 1rem!important;
                    height: 34vw;
                    border-radius: 1rem!important;
                    width: 73vw;
                    align-items: flex-start;
                    margin-left: 0!important;
                   
                    .content-text{
                        flex-direction: column;
                        align-items: flex-start;
                        z-index: 1;
                    }
                    .img-depa{
                        z-index: 1;
                        display: flex;
                        width: 32vw;
                        position: absolute;
                        right: 0;
                        transform: scale(1.2);
                    }
                    .text-link{
                        color: white!important;
                        width: 41vw;
                        margin-bottom: 1vw;
                        
                    }
                    .sub-text-link{
                        font-size: 4vw;
                    }
                }
            }
            .depa::after{
                content: "";
                @media(max-width:600px){
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    // background-image: url("../../Assets/images/icons/background-nav.png");
                    background-image: url("../../Assets/iconos/casaideal.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 1rem !important;
                }
            }
        }
        
        .link-buttons.redes{
            @media(min-width:600px){
                display: none;
            }
            @media(max-width:600px){
                display: flex;
                flex-direction: row;
                margin-top: 6vw;
                .item{
                    width: 12vw;
                    height: 12vw;
                    margin: 0 1.6vw;
                    border-radius: 100%;
                    background-color: #d7d6d6;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    div{
                        width: 50%;
                        height: 50%;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                    }
                }
                .item:first-child{
                    margin-left: 0;
                }
            }
        }
        .line{
            height: 100%;
            width: 1px;
            background-color: rgb(175, 175, 175);
            margin: 0 .5rem;
        }
        .link.button{
            color: white;
            background: transparent;
            border: 1px solid white;
            border-radius: 100rem;
            height: 1.9rem;
            width: 1.9rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: .5rem;
            padding: 0;
            opacity: 1;
            @media(max-width:1200px){
                color: var(--primary);
            }
            @media(max-width:800px){
                padding: 0;
                height: 2.6rem;
                width: 2.6rem;
            }
            &.button_cart{
                border-radius: 0;
                border: 0;
                .cart-buy{
                    width: 75%;
                    height: 75%;
                }
            }
            .redes{
                width: 45%;
                height: 42%;
                background: white;
            }
            .content-text{
                .text-link{
                    color: white;
                    @media(max-width:1200px){
                        color: var(--primary);
                    }
                }
            }
        }
        .link.button:hover{
            background: white;
            border: 1px solid white;
            background: white;
            color: var(--primary);
            .redes{
                background: var(--red);
            }
        }
        .link.button.button_cart{
            background-color: transparent;
            border: 0;
        }
        .link:not(.active){
            .line{
                animation: .4s linkInactive forwards;
            }
            @keyframes linkInactive {
                0%{
                    width: 100%;
                }
                100%{
                    width: 0;
                    display: none;
                    opacity: 0;
                }
            }
        }
        .link:hover{
            .text-link{
                color: white;
            }
            @media(max-width:1200px){
                font-weight: 400;
            }
        }
        .link.active{
            .text-link{
                font-weight: bold;
                color: var(--red);
            }
            .barra-line{
                width: 0.5rem;
                height: 0.5rem;
                background-color: var(--red);
                @media(min-width:740px){
                    // display: none;
                }
            }
        }
        .barra{
            @media(max-width:1200px){
                display: none;
            }
        }
    }
    .container-link.active{
        @media(max-width:1200px){
            height: 100vh;
        }
    }
    .brand{
        display: flex;
        height: 100%;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        @media(max-width:740px){
            padding-top: 1.3rem;
            padding-bottom: 1.3rem;
            z-index: 2;
        }
        img{
            height: 100%;
        }
        .logo-white{
            position: relative;
        }
        .logo-sky{
            position: absolute;
            display: none;
        }
    }
}
.btn-cant-tipos_selected{
    height: 1.4rem;
    width: 1.4rem;
    background-color: var(--red);
    color: #fff;
    border-radius: .8rem;
    position: absolute;
    right: -.7rem;
    top: -.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .7rem;
    padding-top: .1rem;
}
.navbar.active{
    background-color: white;
    box-shadow: 0px 0px 10px 0px #0000001f;
    .brand{
        .logo-white{
            position: absolute;
            display: none;
        }
        .logo-sky{
            display: block;
            position: relative;
        }
    }
    .content-nav{
        .barra{
            background: var(--primary);
            opacity: .5;
        }
        .toggle{
            .line{
                background: white;
                @media(max-width:1200px){
                    background: white;
                }
            }
        }
    }
    .container-link{
        .link{
            color: var(--primary);
        }
        .link.button{
            color: white;
            background: var(--primary);
            border: 1px solid white;
            border-radius: 100rem;
            padding: 0 1rem;
            @media(max-width:1200px){
                background: transparent;
            }
            @media(max-width:600px){
                padding: 0;
            }
        }
        .link.button:hover{
            .content-text{
                .text-link{
                    color: white;
                }
            }
        }
    }
    
}
.MuiPaper-root{
    background: black;
}
.MuiAccordionSummary-content.Mui-expanded{  
    margin-top: 0!important;
}
.MuiButtonBase-root{
    padding: 0!important;
    background: black;
    min-height: auto!important;
    margin-bottom: 1rem;
    .MuiAccordionSummary-content{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black!important;
        margin-bottom: 0!important;
        margin-top: 0!important;
        @media(max-width:500px){
            justify-content: flex-start;
        }
    }
    .MuiTypography-root{
        display: flex;
        align-items: center;
        margin: 0;
        font-size: 1.5rem;
        color: white;
        font-family: "nexa-book"!important;
        @media(max-width:500px){
            font-size: 1.3rem;
            padding-left: 2.5rem;
            padding-bottom: .5rem;
        }
    }
}
.acordeon-1{
    margin-bottom: 1rem;
}
.expand{
    width: 1rem;
    height: 1rem;
    margin-left: 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: .3rem;
    transition: 0.2s;
    .linea{
        width: 2px;
        height: 100%;
        background: white;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        margin: auto;
        border-radius: 10rem;
        transition: 0.2s;
    }
    .line-2{
        transform: rotate(90deg);
    }
}
.expand.active{
    transform: rotate(180deg);
    .line-1{
        transform: rotate(90deg);
    }
}
.MuiCollapse-container{
    
    .MuiAccordionDetails-root{
        background: black;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width:500px){
            background: rgb(20, 20, 20);
            align-items: center;
            justify-content: flex-start;
            padding-left: 2.5rem;
        }
        .MuiTypography-root{
            display: flex;
            flex-direction: column;
            align-items: center;
            background: black;
            @media(max-width:1200px){
                align-items: flex-start;
            }
            @media(max-width:500px){
                align-items: flex-start;
                background: transparent;
            }
            .project{
                color: white;
                font-family: "nexa-book"!important;
                font-size: 1.3rem;
                display: flex;
                @media(max-width:1200px){
                    font-size: 1rem;
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                    opacity: .7;
                    .name{
                        width: 5rem;
                        display: flex;
                    }
                }
            }
        }
    }
}