@mixin flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-calculate{
    mask-image: url("../../Assets/iconos/calculate.svg");
}
.icon-separe{
    mask-image: url("../../Assets/iconos/separar.svg");
}
.icon-consult{
    mask-image: url("../../Assets/iconos/consultar.svg");
}
#root{
    width: 100%;
}
.ant-modal{
    width: 30rem!important;
}
.ant-modal-content{
    .ant-modal-header{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0;
        border: 0;
        padding-top: 1.5rem;
        .ant-modal-title{
            margin-top: 8vw;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.2rem;
            margin-top: 1rem;
            margin-bottom: .5rem;
            @media(max-width:740px){  
                margin-top: 8vw;
                font-size: 6vw;
            }
        }
    }
    .ant-modal-body{
        padding-top: 0;
        padding-bottom: 1vw;
        padding-bottom: 1vw;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        .parrafo{
            text-align: center;
            width: 100%;
            margin-bottom: 2rem;
        }
        .content-input{
            &.long{
                width: 100%;
                select{
                    width: 100%;
                    border: 0;
                    border-bottom: 1px solid black;
                    padding: 0.5rem;
                    padding-left: 2rem;
                }
            }
            .icon{
                top: 0;
                bottom:.3vw;
                right: auto;
                left: 0;
                margin:auto;
                background: black;
                position: absolute;
                mask-repeat: no-repeat;
                mask-position: center;
                height: 1.1vw;
                width: 1.1vw;
                @media(max-width:740px){
                    bottom: 1.5vw;
                    height: 5vw;
                    width: 5vw;
                }
            }
            input{
                margin: 0;
                padding-left: 2vw;
                @media(max-width:740px){
                    padding-left: 8vw;
                }
            }
        }
        .user-content{
            width: 47%;
            @media(max-width:740px){
                width: 100%;
            }
        }
    }
    .ant-modal-footer{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 2vw;
        border: 0;
        padding-top:0;
        @media(max-width:740px){
            padding-bottom: 8vw;
        }
        .ant-btn{
            display: none;
        }
        .ant-btn.ant-btn-primary{
            width: 8vw;
            height: 2.5vw;
            font-size: 1vw;
            display: block;
            border-radius: 100rem;
            background-color: var(--red);
            border: 0;
            font-family: "nexa-book";
            @media(max-width:740px){
                width: 32vw;
                height: 10vw;
                font-size: 5vw;
            }
        }
    }
    .ant-modal-close{
        position: absolute;
        // right: -1.5rem;
        // top: -1.5rem;
        margin: auto;
        background: white;
        border-radius: 100%;
        // box-shadow: 0 0 10px 0 #00000030;
        width: 3rem;
        height: 3rem;
        @media(max-width:740px){
            top: -7vw;
            width: 13vw;
            height: 13vw;
            left: 0;
            right: 0;
        }
        .anticon,.ant-modal-close-x{ 
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                width: 50%;
                height: 50%;
            }
        }
    }
}
.filtro.town{
    --Secondary: #233442;
    --Yellow:#f1605f;
    --button:white;
    --hoverbutton:#233442;
}
.filtro.nou{
    --Secondary: #4a4a49;
    --Yellow:#f9b450;
    --button:white;
    --hoverbutton:#4a4a49;

    .techado{
        visibility: hidden;
    }    
}
.visible-dorm{
    .techado{
        visibility: visible!important;
    }
}
.filtro.trend{
    --Secondary: #362e2c;
    --Yellow:#973921 ;
    --button:white;
    --hoverbutton:white;
    .techado{
        visibility: hidden;
    } 
}
.filtro.unity{
    --Secondary: #362e2c;
    --Yellow:#973921 ;
    --button:white;
    --hoverbutton:white;
     
}
.filtro.salaverry{
    --Secondary: #292929;
    --Yellow:#DCB94D ;
    --button:white;
    --hoverbutton:#292929;
}
.flats,.title-filter-unbox{
    display: none!important;
}
.card.choose{
    opacity: 1;
    .btn-card{
        background:var(--Yellow)
    }
}
.card.opacity{
    opacity: .7;
}
.filtro.unbox{
    --button:white;
    --Yellow:#eab280!important;
    --hoverbutton:#292929;
    #dorm-1{
        position: absolute;
        visibility: hidden;
    }
    .flats{
        display: flex!important;
    }
    .plano{
        .title-line{
            display: none!important;
        }
    }
    .plano{
        .title-filter-unbox{
            display: flex!important;
            flex-direction: column;
        }
    }
    .content-filter{
        .controller{
            height: 47vw;
            position: absolute;
            .dormitorios{
                .flex-row{
                    .btn-num{
                        width: 3vw!important;
                        height: 3vw!important;
                        background: transparent;
                        border:1px solid white;
                        color:white;
                        margin-right: .6vw;
                        margin-left: 0;
                    }
                    
                    .btn-num:hover{
                        background: white;
                        color: var(--Secondary);
                    }
                    .btn-num.active{
                        border-color: var(--Yellow);
                    }
                    .btn-num.active:hover{
                        background: var(--Yellow)!important;
                    }
                    #btn-4{
                        width: 6.6vw!important;
                    }
                }

            }
        }
    }
}

.filtro{
    background: #F7F7F7;
    .active{
        fill:var(--Yellow)!important;
        transition: .7s;
    }
    #poly-1,#poly-2,#poly-3,#poly-4,#poly-5,#poly-6,#poly-7{
        transition: .7s;
        fill: var(--Secondary);
    }
    .none{
        display: none!important;
    }
    .title-main{
        font-size: 1.6vw;
        font-family: "nexa-book";
        font-weight: 100;
        padding: 6vw 0 4vw 9.7vw;
        letter-spacing: 0.113vw;
        color: var(--Secondary);
        .bold{
            font-family: "nexa-heavy";
        }
    }
    .btn-select{
        display: none;
     }
    .content-filter{
        width: 100%;
        .controller{
            background: var(--Secondary);
            color: white;
            width: 30%;
            height: 40vw;
            padding-left: 9.2vw;
            padding-top: 1.9vw;
            position: absolute;
            .plano{
                .side-room{
                    display: none;
                }
            }
            .title{
                font-size: 1.2vw;
                font-weight: 100;
                font-family: "nexa-book";
                display: block;
                margin-bottom: 0.2vw;
            }
            .plano{
                .flats{
                    display: flex;
                    flex-direction: row;
                    padding: 1.5vw 0;
                    flex-wrap: wrap;
                }
                .btn-flat{
                    width: 3vw;
                    height: 3vw;
                    border: 1px solid white;
                    margin: .3vw .3vw;
                    margin-right: .6vw;
                    margin-left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: .5s;
                    font-size: .9vw;
                }
                .flat-all{
                    width: 6.6vw;
                }
                .btn-flat.active{
                    background: var(--Yellow);
                    color: var(--hoverbutton);
                    border-color: var(--Yellow);
                }
                .btn-flat.none{
                    display: flex!important;
                }
                .btn-flat.active:hover{
                    background: var(--Yellow)!important;
                }
                .btn-flat:hover{
                    background: white;
                    color: var(--Secondary);
                }
                .plano-svg{
                    height: 16.65vw;
                    padding-right: 3vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 2vw;

                    svg{
                        height: 95%;
                        width: 8.5vw;
                        position: relative;
                        left: 0;
                        top: 1.2vw;
                        right: 0;
                        margin: auto;
                        polygon{
                            cursor: pointer;
                        }
                        .cls-1 {
                            fill: var(--Secondary);
                        }
                    }
                }
                
            }
            .title-line::after{
                content: "";
                position: relative;
                display: block;
                border-bottom: 1px solid white;
                left: 0%;
                width: 85.5%;
            }
            .dormitorios{
                height: 12vw;
                .title-line{
                    margin-bottom: 1.1vw;
                }
                .flex-row{
                    // padding-left: 1.1vw;
                    .btn-num{
                        height: 2.4vw;
                        padding: 0 1vw;
                        background: white;
                        color: black;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 1.1vw;
                        cursor: pointer;
                        font-size: 0.9vw;
                        transition: .3s;
                    }
                    .btn-num.active{
                        background: var(--Yellow);
                        color: var(--hoverbutton);
                    }
                }
                .line-2{
                    padding-left: .95vw;
                    margin-top: 1vw;
                    display: none;
                    .btn-num{
                        background: transparent;
                        border:1px solid white;
                        opacity: 0.1;
                        color: white;
                        box-sizing: content-box;
                        margin-right: .85vw;
                    }
                }
            }
            .area-total{
                .title-line{
                    display: none;
                }
                .reset{
                    height: 3.1vw;
                    width: 14vw;
                    border: 1px solid;
                    background: transparent;
                    color: white;
                    position: relative;
                    top: 1vw;
                    left: 1.9vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: .9vw;
                    cursor: pointer;
                }
            }

        }
        .result{
            padding: 0 2.2vw;
            width: 70%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            height: 50rem;
            @media(max-width:500px){
                height: auto;
            }
            .mensaje{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1vw;
                text-align: center;
                font-family: "nexa-book";
            }
            .mensaje.inactivo{
                display: none;
            }
            .card:hover{
                
                @media(min-width:740px){
                    transform: scale(1.02);
                }
            }
            
            .card{
                .attributes{
                    .area-desde{
                        display: none;
                    }
                }
            }
            .card.desde{
                .attributes{
                   
                    .techado{
                        display: none;
                    }
                    .flex-row{
                        visibility: hidden;
                    }
                    .area-desde{
                        display: flex;
                        visibility: visible!important;
                    }
                }
            }
            .card:hover > .btn-card{
                color:var(--hoverbutton);
                background: var(--Yellow);
            }
            .card:hover{
                .content-img{
                    .lupa{
                        visibility: visible;
                        background: rgba(255, 255, 255, 0.336);
                    }
                }
            }
        }
    }
}


.ant-modal{
    @media(orientation:landscape){
        width: 29rem!important;
    }
}
.ant-modal-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    .content-input{
        position: relative;
        margin-bottom: 1rem;
    }
    input {
        font-family: "nexa-book";
        width: 100%;
        margin: 4%;
        padding: 2%;
        border: 1px solid grey;
        &.invalid {
            border-color: red;
        }
        border: 0;
        border-bottom: 1px solid;
    }
    input::placeholder{
        font-family: "nexa-book";
    }
}

.ant-modal-footer {
    button.ant-btn-primary {
        border-color: #4a4a49;
        background-color: #4a4a49;
    }
}
.filtro.unity{
    --Yellow:#ff7f2f;
    --Secondary: #003056;
    --hoverbutton:white;
    --button:white;
}
.filtro.midway{
    --Secondary: #25313D!important;
    --Yellow:#CCBB9A !important;
    --button:white;
    --hoverbutton:white;
}
.filtro.unbox{
    .content-filter{
        .controller{
            .plano{
                // display: none!important;
                .plano-svg{
                    display: none;
                }
            }
        }
    }
}
.filtro.unity,.filtro.midway{
    .content-filter{
        .dormitorios{
            .btn-num{
                background: transparent!important;
                border: 1px solid white;
                color: white!important;
            }
            .btn-num.active{
                background: var(--Yellow)!important;
                border: 1px solid var(--Yellow);
            }
        }
        .controller{
            .plano{
                // display: none!important;
                .plano-svg{
                    display: none;
                }
                .flats{
                    display: none;
                }
                .side-room{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 1vw;
                    padding-right: 0vw;
                    padding-left: 0;
                    .btn-side{
                        width: 8.45vw !important;
                        height: 2.4vw!important;
                    }
                    .btn-side:hover{

                    }
                }
            }
        }
    }
}