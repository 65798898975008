
.contacto{
    padding-top: 3vw;
    // background: var(--Yellow);
    *{
        font-family: "nexa-book";
    }
    .title{
        text-align: center;
        .bold{
            font-weight: bold;
        }
    }
    .paragraph{
        font-size: 1.2vw;
        text-align: center;
        letter-spacing: 0.045vw;
    }
    .formulario{
        display: flex;
        flex-wrap: wrap;
        padding: 0rem;
        justify-content: space-between;
        align-items: center;
        width: 70%;
        margin: auto;
        @media(max-width:1100px){
            width: 85%;
        }
        @media(max-width:740px){
            width: 100%;
        }
        .input{
            border: 0;
            border-bottom: 1px solid var(--dark);
            width: 48%;
            height: 3rem;
            margin-bottom: 1.5rem;
            font-size: 1rem;
            // color: var(--title);
            font-family: "nexa-book";
            @media(max-width:500px){
                width: 100%;
                background: white;
            }
            &.invalid {
                border-color: red;
                color: red;
                &::placeholder {
                    color: red;
                }
            }
        }
        select[name="rooms_amount"]{
            color: black!important;
            option{
                color: black!important;
            }
        }
        [type=checkbox].invalid ~ label{
            border-color: red;
        }
        .input::placeholder{
            font-size: 1rem!important;
        }
        .large{
            width: 100%;
        }
        .input::placeholder{
            font-size: 1.3vw;
            font-family: "nexa-book";
            // color: var(--title);
        }
        .button-radius{
            margin-bottom: 2rem;
            margin-top: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
        .button-title{
            width: 1rem;
            height: 1rem;
            border-radius: 100%;
            border: 1px solid;
            margin-right: .5rem;
            cursor: pointer;
            @media(max-width:600px){
                top: -.1rem;
                position: relative;
            }
        }
        .radio{
            font-size: 1rem;
            display: flex;
            align-items: center;
            @media(max-width:740px){
                font-size: .8rem;
                display: flex;
                flex-direction: row;
                white-space: nowrap;
            }
            .button-title{
                position: relative;
                top: -.15rem;
            }
        }
        #terminos,#terminos-y-condiciones{
            position: absolute;
            visibility: hidden;
        }
        #terminos:checked ~ .button-title,#terminos-y-condiciones:checked ~ .button-title{
            background: var(--dark);
        }
        .terminos{
            color: var(--dark);
            text-decoration: underline;
        }
        .btn{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 3rem;
            @media(max-width:500px){
                margin-bottom: 0;
            }
            .btn-submit{
                transition: .3s;
                background-color: var(--red);
                color: white;
                padding: 0 2rem;
                padding-top: .1rem;
                @media(max-width:500px){
                    width: 40vw;
                    background: var(--red);
                    color: white;
                    font-size: 4.5vw;
                }

            }
            .btn-submit:hover{
                background-color:var(--red);
                color: white
            }
        }
    }
}
