.icon-place{
    mask-image: url("../Assets/iconos/place.svg");
}
.rule{
    mask-image: url("../Assets/iconos/rule.svg");
}
.dorm{
    mask-image: url("../Assets/iconos/dorm.svg");
}
.edificio{
    mask-image: url("../Assets/iconos/edificio.svg");
}
.lampara{
    mask-image: url("../Assets/iconos/lampara.svg");
}
.lamp-malecon{
    mask-image: url("../Assets/iconos/lampara.svg");
}
.gas{
    mask-image: url("../Assets/iconos/gas.svg");
}
.camara{
    mask-image: url("../Assets/iconos/camara.svg");
}
.lobby{
    mask-image: url("../Assets/iconos/areas/lobby.svg");
}
.terraza{
    mask-image: url("../Assets/iconos/terraza.svg");
}
.bebida{
    mask-image: url("../Assets/iconos/bebida.svg");
}
.bici {
    mask-image: url("../Assets/iconos/bici.svg")
}
.coffee {
    mask-image: url("../Assets/iconos/coffee.svg");
}
.coworking{
    mask-image: url("../Assets/iconos/areas/bar.svg");
}
.pet{
    mask-image: url("../Assets/iconos/pet.svg");
}

.lobby{
    mask-image: url("../Assets/iconos/areas/lobby.svg");
}
.parrilla{
    mask-image: url("../Assets/iconos/areas/terraza.svg");
}
.copa{
    mask-image: url("../Assets/iconos/areas/bebida.svg");
}
.coworking{
    mask-image: url("../Assets/iconos/areas/bar.svg");
}
.pet{
    mask-image: url("../Assets/iconos/areas/pet.svg");
}
.sillon{
    mask-image: url("../Assets/iconos/areas/sillon.svg");
}
.refri{
    mask-image: url("../Assets/iconos/areas/refri.svg");
}
.bed{
    mask-image: url("../Assets/iconos/areas/bed.svg");
}
.famele{
    mask-image: url("../Assets/iconos/areas/famele.svg");
}
.male{
    mask-image: url("../Assets/iconos/areas/male.svg");
}    
.ducha{
    mask-image: url("../Assets/iconos/areas/ducha.svg");
}
.excavadora{
    mask-image: url("../Assets/iconos/areas/excavator.svg");
}
.sala-coworking{
    mask-image: url("../Assets/iconos/areas/sala-coworking.svg");
}
.game{
    mask-image: url("../Assets/iconos/areas/gamer.svg");
}
.gym{
    mask-image: url("../Assets/iconos/areas/gym.svg");
}
.icon-terraza{
    mask-image: url("../Assets/iconos/areas/icon-terraza.svg");
}
.zona-child{
    mask-image: url("../Assets/iconos/areas/zona-child.svg");
}
.lavado{
    mask-image: url("../Assets/iconos/areas/lavado.svg");
}
.relax{
    mask-image: url("../Assets/iconos/areas/relax.svg");
}
.yoga{
    mask-image: url("../Assets/iconos/areas/yoga.svg");
}
.area-chill{
    mask-image: url("../Assets/iconos/areas/area-chill.svg");
}
.sala-estar{
    mask-image: url("../Assets/iconos/areas/sala-de-estar.svg");
}
.sky-bar{
    mask-image: url("../Assets/iconos/areas/sky-bar.svg");
}
.jardin{
    mask-image: url("../Assets/iconos/areas/jardin.svg");
}
.sala-juegos{
    mask-image: url("../Assets/iconos/areas/sala-juegos.svg");
}
.sensores{
    mask-image: url("../Assets/iconos/areas/sensores.svg");
}
.area-child{
    mask-image: url("../Assets/iconos/areas/area-child.svg");
}
.cercania{
    mask-image: url("../Assets/iconos/areas/cercania.svg");
}
.area-verde{
    mask-image: url("../Assets/iconos/areas/area-verde.svg");
}
.sala-de-espera{
    mask-image: url("../Assets/iconos/areas/sala-de-espera.svg");
}
.fogata{
    mask-image: url("../Assets/iconos/areas/fogata.svg");
}
.piscina{
    mask-image: url("../Assets/iconos/piscina.svg");
}
.flower{
    mask-image: url("../Assets/iconos/flower.svg");
}
.lg{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lg-outer.tipologies,.lg.tipologies{
    .lg-toolbar.lg-group{
        background-color: transparent!important;
    }
    .lg-inner{
        height: 90vh!important;
        position: relative!important;
        @media(max-width:500px){
            width: 90vw!important;
            height: 90vw!important;
        }
    }
    // .lg-sub-html{
    //     position: relative!important;
    //     h4{
    //         color: white;
    //     }
    //     .content-data-tipo{
    //         background-color: white;
    //         width: 88rem;
    //         margin: auto;
    //         color: black;
    //         padding: 1.6rem;
    //         @media(max-width:1400px){
    //             width: 70rem;
    //         }
    //         @media(max-width:500px){
    //             width: 90vw;
    //         }
    //         h3{
    //             color: black;
    //         }
    //         .data-tipologies{
    //             display: flex;
    //             flex-direction: row;
    //             flex-wrap: wrap;
    //             font-size: .8rem;
    //             @media(max-width:1400px){
    //                 font-size: .7rem;
    //             }
    //             .unit{
    //                 width: 21rem;
    //                 display: flex;
    //                 margin-bottom: .5rem;
    //                 @media(max-width:1400px){
    //                     width: 16rem;
    //                 }
    //             }
    //         }
    //     }
    // }
}
//

.btn-radio{
    border-radius: 100rem;
    padding-top: .1rem;
}
.outline{
    border: 1px solid var(--red);
}
.title{
    font-size: 1.3rem;
    font-weight: 100;
    @media(min-width:1400px){
        font-size: 1.5rem;
    }
    @media(max-width:500px){
        font-size: 5vw;
    }
}
.bold{
    font-family: "nexa-heavy";
}
.br-movil{
    @media(max-width:500px){
        display: block;
    }
}
.paragraph{
    font-size: 1.1rem;
    margin-bottom: 1rem;
    @media(max-width:500px){
        font-size: 4.5vw;
    }
}
.information{
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    position: relative;
    @media(max-width:1000px){
        flex-wrap: wrap;
    }
    @media(max-width:800px){
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    @media(min-width:1400px){
        padding-top: 5rem;
    }
    .info{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 50%;
        @media(max-width:800px){
            margin-bottom: 5rem;
            align-items: center;
            width: 100%;
        }
        .handing-over{
            font-size: 1.3rem;
            margin-bottom: 1rem;
            @media(max-width:800px){
                text-align: center;
            }
            @media(max-width:500px){
                font-size: 4.5vw;
                margin-bottom: 1vw;
            }
        }
        .separe{
            @media(max-width:500px){
                margin-bottom: 8vw;
            }
        }
        .title{
            font-size: 1.3rem;
            margin-bottom: 1rem;
            @media(max-width:800px){

            }
            @media(max-width:800px){
                text-align: center;
            }
            @media(max-width:500px){
                font-size: 5vw;
                margin-bottom: 1vw;
                width: 66vw;
                margin-bottom: 4vw;
            }
        }
        .paragraph{
            width: 88%;
            font-size: 1rem;
            @media(min-width:1400px){
                font-size:1.2rem;
            }
            @media(max-width:740px){
                width: 100%;
            }
            @media(max-width:500px){ 
                font-size: 4.2vw!important;
                text-align: center;
            }
        }
        .paragraph-1{
            margin-bottom: 1rem;
            @media(max-width:500px){
                margin-bottom: 4vw;
            }
        }
        .paragraph-2{
            margin-bottom: 2rem;
            @media(min-width:1400px){
                margin-bottom: 3rem;
            }
            @media(max-width:740px){
                margin-bottom: 2rem;
            }
            @media(max-width:500px){
                margin-bottom: 6vw;
                display: none;
            }
        }
        .paragraph-2.active{
            display: block;
        }
        .skip{
            text-decoration: underline;
            color: var(--red);
            margin-bottom: 6vw;
            @media(min-width:500px){
                display: none;
            }

        }
        .place{
            margin-bottom: .5rem;
            display: flex;
            line-height: 1rem;
            align-items: center;
            @media(max-width:500px){
                justify-content: center;
            }
            .mask{
                width: 1.2rem;
                height: 1.3rem;
                background: var(--dark);
                margin-right: 1rem;
                margin-bottom: .4rem;
                @media(max-width:500px){
                    width: 3vw;
                    height: 5vw;
                    top: 0vw;
                    margin-right: 2.1vw;
                    position: relative;
                    min-width: 1rem;
                    -webkit-mask-position: center;
                }
            }
        }
        .icon-place.top{
            @media(max-width:500px){
                top: -2vw;
                position: relative;
            }
        }
        .btn-radio{
            padding: .7rem 1.5rem;
            color: var(--red);
            font-family: "nexa-bold";
            margin-top: 1rem;
            transition: .3s;
            font-size: 1.1rem;
            font-family: "nexa-book";
            font-size: .9rem;
            padding-top: .8rem;
            font-weight: bold;
            @media(min-width:1400px){
                font-size: 1rem;
            }
            @media(max-width:500px){
                font-size: 4.5vw;
            }
        }
        .btn-radio:hover{
            color: white;
            background-color: var(--red);
        }
        .icon-place{
            mask-size: 68% 80%;
        }
    }
    .info::after{
        content: "";
        position: absolute;
        top: 0;
        width: 1px;
        background: black;
        height: 100%;
        left: 50%;
        opacity: .1;
        @media(max-width:800px){
            display: none;
        }
    }
    .form{
        width: 50%;
        @media(max-width:800px){
            width: 100%;
        }
        .contacto{
            padding-top: 0;
            .paragraph{
                font-size:1rem;
                @media(min-width:1400px){
                    font-size:1.2rem;
                }
                @media(max-width:500px){
                    font-size: 4.5vw!important;
                }
            }
        }
    }
    .content-all{
        display: flex;
        width: 88%;
        justify-content: space-between;
        @media(max-width:740px){
            width: 100%;
            flex-wrap: wrap;
        }
    }
    .content-list-2{
        margin-bottom: 2rem;
    }
    .content-list{
        display: flex;
        flex-direction: column;
        width: 49%;
        @media(max-width:740px){
            width: 100%;
        }
        .title-list{
            font-size: 1rem;
            font-weight: bold;
            font-family: 'nexa-bold';
            @media(max-width:500px){
                font-size: 4.5vw;
            }
        }
        .title-list{
            margin-top: 2rem;
        }
    }
    .table{
        display: flex;
        flex-direction: column;
        width: 100%;
        
        .name-product{
            font-size: .6rem;
            display: flex;
            align-items: center;
            
            @media(min-width:1400px){
                font-size: .7rem;
            }
            @media(max-width:500px){
                font-size: 4vw !important;
                padding-right: 3vw;
            }
        }
        .cant-product{
            font-size: .6rem;
            @media(max-width:500px){
                font-size: 4vw !important;
            }
        }
        .header-table{
            display: flex;
            border-bottom: 1px solid black;
            align-items: center;
            justify-content: space-between;
            font-size: .8rem;
            font-weight: bold;
            padding: .5rem 0;
            @media(min-width:1400px){
                font-size: 1rem;
            }
            @media(max-width:500px){
                font-size: 4.5vw;
            }
            .name-product{
                text-align: right;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                padding-left: 2.5rem;
                @media(min-width:1400px){
                    padding-left: 3.5rem;
                }
                @media(max-width:740px){
                    padding-left: 16.5vw;
                }
            }
            .cant-product{
                width: 3.5rem;
                min-width: 3.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                @media(max-width:500px){
                    width: 28vw;
                }
            }
        }
        
        .body-table,.line-product{
            display: flex;
        }
        .body-table{
            flex-direction: column;
            .line-product{
                padding: 0.5rem 0;
                justify-content: space-between;
                .cant-product{
                    width: 3.5rem;
                    min-width: 3.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media(max-width:500px){
                        width: 28vw;
                        min-width: 28vw;
                    }
                }
            }
            .img-product{
                margin-right: .5rem;
                width: 2rem;
                @media(min-width:1400px){
                    width: 3rem;
                }
                @media(max-width:500px){
                    margin-right: 2vw;
                    width: 14vw;
                }
            }
        }
    }
}
.section-atributos{
    display: flex;
    flex-direction: column;
    background-color: var(--dark);
    padding: 2rem 0;
    position: relative;
    overflow: hidden;
    @media(max-width:740px){
        padding-right: 1rem;
        padding-left: 1rem;
    }
    @media(max-width:500px){
        padding-bottom: 0;
    }
    .background-black{
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
    }
    .title{
        color: white;
        text-align: center;
        display: block;
        margin-top: 3rem;
        z-index: 1;
        @media(max-width:500px){
            margin-top: 1.5rem;
        }
    }
    .content-atributos{
        display: flex;
        flex-wrap: wrap;
        width: 53rem;
        margin: auto;
        padding: 3rem 0;
        align-items: center;
        z-index: 1;
        gap: 0 1rem;
        @media(min-width:1400px){
            gap: 0 3.5rem;
            width: 63rem;
        }
        @media(max-width:1100px){
            width: 31rem;
        }
        @media(max-width:900px){
            width: 30rem;
            gap: 0;
        }
        @media(max-width:740px){
            width: 100%;
            padding-top: 1rem;
        }
    }
    .item{
        display: flex;
        align-items: center;
        width: 15.65rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
        @media(min-width:1400px){
            width: 18.65rem;
        }
        @media(max-width:1100px){
            width: 15rem;
        }
        @media(max-width:740px){
            width: 50%;
            flex-direction: column;
        }
        .mask{
            margin-right: .6rem;
            width: 4rem;
            height: 4rem;
            min-width: 4rem;
            background-color: red;
            @media(min-width:1400px){
                margin-right: 1rem;
            }
            @media(max-width:740px){
                margin-bottom: 1rem;
            }
            @media(max-width:500px){
                margin-bottom: 0rem;
                margin-right: 0;
            }
        }
        .mask.none{
            background: transparent;
        }
        .text{
            color: white;
            font-size: .9rem;
            margin-top: .5rem;
            @media(min-width:1400px){
                font-size: 1.1rem;
            }
            @media(max-width:740px){
                font-size: 1rem;
            }
            @media(max-width:500px){
                font-size: 3.5vw;
                text-align: center;
            }
        }
    }
}
.description{
    padding-top: 5rem;
    height: 25rem;
    @media(min-width:1200px){
        height: 21rem;
    }
    @media(min-width:1400px){
        height: 29rem;
    }
    @media(max-width:1000px){
        height: auto;
    }
    @media(max-width:500px){
        padding-top: 14vw;
    }
    .title{
        margin-bottom: 1rem;
        @media(max-width:500px){
            font-size: 5vw;
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .container{
        display: flex;
        @media(max-width:1000px){
            flex-wrap: wrap;
            justify-content: center;
        }
        @media(max-width:700px){
            justify-content: space-between;
        }
    }
    .content-info{
        margin-left: 1rem;
        margin-bottom: 2rem;
        @media(max-width:1000px){
            margin-left: 0;
            order: 1;
        }
        .title{
            @media(max-width:1000px){
                text-align: center;
            }
        }
        .paragraph{
            font-size: 1rem;
            @media(min-width:1400px){
                font-size: 1.2rem;
            }
            @media(max-width:1000px){
                text-align: center;
            }
        }
    }
    .fachada,.render{
        margin-right: 2rem;
        height: 23rem;
       
        @media(max-width:1000px){
            height: auto;
            width: 31rem;
            object-fit: cover;
            object-position: center;
        }
        @media(min-width:1000px){
            height: 16rem;
        }
        @media(min-width:1200px){
            height: 20rem;
        }
        @media(min-width:1400px){
            height: 24rem;
        }
        @media(min-width:1600px){
            height: 27rem;
        }
        @media(max-width:600px){
            width: 100%;
            height: 54vw;
            margin-right: 0;
        }
    }
    .fachada{
        @media(max-width:1000px){
            order: 3;
        }
    }
    .render{
        @media(max-width:1000px){
            margin-right: 0;
            order: 2;
        }
    }
}
.section-filter{
    display: flex;
    padding-top:0;
    margin-bottom: 0rem;
    @media(min-width:1400px){
        margin-bottom: 4rem;
    }
    @media(max-width:1100px){    
        padding-top: 7rem;
    }
    @media(max-width:500px){
        padding-top: 0vw;
        margin-bottom: 10vw;
    }
    .btn-next{
        background-image: url("../Assets/iconos/signal-right.svg");
    }
    .btn-prev{
        background-image: url("../Assets/iconos/signal-left.svg");
    }
    .message{
        font-size: 1.1rem;
        font-weight: bold;
        text-align: center;
        margin: auto;
        display: block;
        padding-right: 1rem;
        padding-left: 1rem;
        @media(max-width:500px){
            height: 124vw;
            display: flex;
            align-items: center;
            font-size: 4vw;
        }
    }
    .container{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 3rem;
        @media(min-width:1100px){
            align-items: flex-start;
        }
        @media(max-width:1100px){
            flex-direction: column;
        }
        @media(max-width:500px){
            padding-top: 0rem;
            padding-bottom: 1rem;
        }
    }
    .title{
        margin-bottom: 3rem;
        @media(min-width:1400px){
            margin-bottom: 3rem;
        }
        @media(min-width:1200px){
            margin-bottom: 2rem;
        }
        @media(max-width:1100px){
            text-align: center;
        }
        @media(max-width:500px){
            margin-bottom: 7vw;
        }
    }
    .sub-title{
        font-size: .9rem;
        margin-bottom: 1rem;
        font-weight: bold;
        @media(min-width:1400px){
            font-size: 1.2rem;
        }
        @media(max-width:1100px){
            text-align: center;
            font-size: 1.2rem;
        }
        @media(max-width:500px){
            font-size: 4.5vw;
            margin-bottom: 3vw;
        }
    }
    .filter-title{
        margin-bottom: 1rem;
        @media(min-width:1200px){
            margin-bottom: 2rem;
        }
        @media(min-width:1200px){
            margin-bottom: 1rem;
        }
        @media(max-width:1000px){
            text-align: center;
        }
        @media(max-width:500px){
            font-size: 4.5vw;
            margin-bottom: 5vw;
        }
    }
    .options{
        display: flex;
        flex-direction: column;
        padding-top: 3rem;
    }
    .content-dorms,.content-vista{
        display: flex;
        margin-bottom: 1rem;
        flex-wrap: wrap;
        @media(min-width:1200px){
            margin-bottom: 1rem;
        }
        @media(min-width:1400px){
            margin-bottom: 2rem;
        }
        @media(max-width:1100px){
            justify-content: center;
        }
        .btn-radio-2plus{
            @media(max-width:500px){
                margin-right: 0!important;
            }
        }
        .btn-radio{
            border: 1px solid #919191;
            margin-right: 1rem;
            font-weight: bold;
            color: #919191;
            margin-bottom: 1rem;
            @media(max-width:1400px){
                font-size: .8rem;
            }
            @media(max-width:500px){
                font-size: 3.6vw;
            }
        }
        .btn-radio.active{
            background-color: var(--red);
            border-color: var(--red);
            color: white;
        }
        .btn-radio:last-child{
            margin-right: 0;
        }
    }
    .content-dorms{
        &.cant-3{
            .btn-radio-3{
                @media(max-width:550px){
                    margin-right: 1rem;
                }
            }
        }
        .btn-radio-3{
            @media(max-width:500px){
                margin-right: 0;
            }
        }
        .btn-radio{
            @media(max-width:500px){
                width: 23vw;
            }
        }
    }
    .result{
        padding: 3rem 0rem;
        padding-top: 0;
        padding-bottom: 0;
        width: 45rem;
        height: auto;
        overflow: hidden;
        border-radius: 1rem;
        box-shadow: -8px -9px 52px 5px #19191912;
        background: white;
        display: flex;
        @media(min-width:850px){
            height: 29rem;
        }
        @media(min-width:1200px){
            width: 50rem;
            height: 31rem;
        }
        @media(min-width:1400px){
            width: 50rem;
            height: 31rem;
        }
        @media(min-width:1600px){
            width: 62rem;
            height: 31rem;
        }
        @media(max-width:1100px){
            width: 48rem;
            height: auto;
            padding-bottom: 2rem;
        }
        @media(max-width:800px){
            width: 34rem;
            height: auto;
        }
        @media(max-width:600px){
            width: 100%;
            height: auto;
            padding-top: 3vw;
            overflow: initial;
        }
        @media(max-width:600px){
            padding-bottom: 1rem;
            padding-top: 0;
        }
        .content-result{
            height: 100%;
            width: 100%;
            padding: 0rem 0;
            background-color: white;
            @media(min-width:1600px){
                padding: 1rem 0;
                padding-top: 1rem;
            }
            @media(max-width:500px){
                padding-top: 0vw;
                padding-bottom: 2vw;
                border-radius: 1rem;
            }
            
            .data-tipo{
                display: none;
            }
            .content-view{
                width: 100%;
                display: flex;
                margin-bottom: 1rem;
                padding-top: 1rem;
                display: flex;
                align-items: center;
                @media(max-width:500px){
                    flex-direction: column;
                }
                @media(max-width:500px){
                    flex-direction: row;

                }
                .content-img{
                    width: 40%;
                    height: 26rem;
                    position:relative;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media(max-width:500px){
                        width: 100%;
                        height: 18rem;
                    }
                    .expand{
                        pointer-events: none;
                    }
                    .content-img-all{
                        @media(max-width:500px){
                            height: 100%;
                            width: 100%;
                            .item-img{
                                height: 100%;
                                display: none;
                                &.active{
                                    display: flex;
                                    img{
                                        opacity: 1;
                                        height: 100%;
                                        width: 100%;
                                        object-fit: contain;
                                    }
                                }
                                img{
                                    height: 100%;
                                }
                            }
                        }
                    }
                    img{
                        width: 78%;
                        margin: auto;
                        display: block;
                        z-index: 0;
                        position: absolute;
                        opacity: 0;
                        transition: 0s;
                        pointer-events: none;
                        @media(min-width:1400px){
                            width: 85%;
                        }
                        @media(max-width:500px){
                            
                        }
                    }
                    .img.active{
                        opacity: 1;
                        position: relative;
                        pointer-events: all;
                    }
                    .lupa{
                        height: 3rem;
                        width: 3rem;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        transition: .1s;
                        user-select: none;
                        pointer-events: none;
                        transition: .4s;
                        opacity: 0;
                        @media(min-width:1400px){
                            height: 4rem;
                            width: 4rem;
                        }
                    }
                    .expand{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }
                }
                .content-img:hover{
                    .expand{
                        background: #ffffff5e;
                    }
                    .lupa{
                        opacity: 1;
                    }
                }
                .content-img::before{
                    content: "";
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: none;
                }
                .content-img::after{
                    content: "";
                    width: 1px;
                    height: 90%;
                    margin: auto;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    background: rgba(206, 206, 206, 0.356);
                }
                .content-data{
                    width: 60%;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    @media(max-width:1400px){
                        height: 20.5rem;
                    }
                    @media(max-width:500px){
                        flex-direction: row;
                        width: 100%;
                        height: auto;
                    }
                    .brand{
                        width: 31%;
                        margin-bottom: 0.3rem;
                        @media(max-width:1200px){
                            margin-top: 1rem;
                            width: 45%;
                        }
                        @media(min-width:1400px){
                            width: 44%;
                            margin-bottom: 1rem;
                        }
                        @media(max-width:500px){
                            width: 32%;
                            margin-top: 0;
                        }
                    }
                    .content-info{
                        display: flex;
                        width: auto;
                        flex-direction: column;
                        width: 68%;
                        height: auto;
                        margin-bottom: 2rem;
                        @media(min-width:1400px){
                            width: 68%;
                        }
                        @media(max-width:1200px){
                            align-items: center;
                            width: 86%;
                        }
                        @media(max-width:500px){
                            width: 100%;
                        }
                            .content-meters{
                                margin-left: 3vw;
                                width: 64%;
                                display: flex;
                                flex-wrap: wrap;
                            }
                            .element-top{
                                margin-top: auto;
                            }
                            .row{
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-bottom: 1rem;
                                @media(max-width:1100px){
                                    width: 100%;
                                    flex-wrap: wrap;
                                    margin-bottom: 0rem;
                                }
                                @media(max-width:500px){
                                    flex-direction: row;
                                    width: 100%;
                                    justify-content: space-around;
                                    position: relative;
                                }
                                .btn-radio{
                                    border: 1px solid #919191;
                                    margin-right: 1rem;
                                    font-weight: bold;
                                    color: #919191;
                                    padding: 0 1.5rem;
                                    width: 80%;
                                    font-size: .8rem;
                                    padding-top: .1rem;
                                    @media(min-width:1400px){
                                        width: 80%;
                                        margin-right: 0;
                                        font-size: 1rem;
                                    }
                                    @media(max-width:1100px){
                                        width: 100%;
                                        flex-wrap: wrap;
                                        margin-right: 0;
                                        margin-bottom: 1rem;
                                    }
                                }
                                .btn-radio.active{
                                    padding-top: .1rem;
                                    background: var(--red);
                                    color: white;
                                    border: 1px solid var(--red);
                                    width: 80%;
                                }
                                .btn-radio:last-child{
                                    // margin-right: auto;
                                }
                                .image{
                                    @media(max-width:500px){
                                        width: 32%;
                                        img{
                                            width: 100%!important;
                                        }
                                    }
                                }
                                .type-tipo:nth-child(3),.type-tipo:nth-child(4){
                                    margin-top: .4rem;
                                    @media(max-width:500px){
                                        margin-top: .2rem;
                                    }  
                                }
                            }
                            .row:last-child{
                                margin-bottom: 0;
                            }
                        .row-1{
                            width: 87%;
                            display: flex;
                            align-items: center;
                            margin-left: auto;
                            margin-right: auto;
                        }
                        .element-top{
                            margin-top: auto;
                            @media(max-width:1100px){
                                .btn-radio{
                                    width: 80%!important;
                                }
                            }
                        }
                        .row-1::after{
                            @media(max-width:500px){
                                content: "";
                                width: 100%;
                                height: 1px;
                                background: #0000001f;
                                position: absolute;
                                bottom: 0;
                            }
                        }
                        .row:first-child{
                            margin-top: 1rem;
                            margin-bottom: 1.2rem;
                            flex-wrap: wrap;
                            align-items: flex-start;
                            justify-content: flex-start;
                            @media(min-width:1400px){
                                margin-bottom: 1.2rem;
                            }
                            @media(max-width:500px){
                                margin-top: 3vw;
                                margin-bottom: 0vw;
                                padding-bottom: 2vw;
                            }
                        }
                        .tipologia,.area{
                            display: flex;
                            flex-direction: column;
                            font-size: 1rem;
                            align-items: center;
                            justify-content: center;
                            width: 49%;
                            @media(max-width:500px){
                                width: 30%;
                                align-items: flex-start;
                            }
                            .info-title{
                                font-size: .9rem;
                                margin-bottom: r0em;
                                @media(min-width:1400px){
                                    font-size: 1.1rem;
                                }
                                @media(max-width:500px){
                                    font-size: 3vw;
                                    margin-bottom: 0rem;
                                }
                            }
                            .sub-title{
                                // font-weight: bold;
                                margin-bottom: 0;
                                font-size: .9rem;
                                font-family: "nexa-heavy";
                                @media(min-width:1400px){
                                    font-size: 1rem;
                                }
                                @media(max-width:1100px){
                                    font-size: .8rem;
                                }
                                @media(max-width:500px){
                                    font-size: 2.9vw;
                                    text-align: left;
                                }
                            }
                        }
                        .tipologia{
                            width: 49%;
                            font-size: 1rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @media(max-width:500px){
                                width: 24vw;
                                margin-right: 2vw;
                                align-items: flex-start;
                                margin-bottom: 1.5vw;
                            }
                        }
                        .area{
                            @media(max-width:500px){
                                margin-bottom: 1.5vw;
                                width: 24vw;
                            }
                        }
                    }
                }
            }
            .content-selection{
                position: relative;
                width: 91%;
                margin: auto;
                margin-bottom: 0;
                margin-top: 0;
                @media(max-width:500px){
                    margin-bottom: 5vw;
                }
                .content-slider{
                    width: 85%;
                    margin: auto;
                    overflow: hidden;
                    @media(max-width:500px){
                        width: 93%;
                    }
                }
                .slick-slide > div{
                    cursor: pointer;
                }
                .card{
                    padding-right: 5%;
                    padding-left: 5%;
                    
                    @media(min-width:600px){
                        width: 6rem!important;
                    }
                    @media(min-width:800px){
                        width: 7.5rem!important;
                    }
                    @media(min-width:1100px){
                        width: 6.2rem!important;
                    }
                    @media(min-width:1200px){
                        width: 7.4rem!important;
                    }
                    @media(min-width:1400px){
                        width: 9.6rem!important;
                    }
                    @media(max-width:700px){
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    @media(max-width:500px){
                        min-width: 25.7vw;
                    }
                    .content-card{
                        border-radius: 1rem;
                        border: 1px solid #eaeaea;
                        overflow: hidden;
                        padding-top: 0rem;
                        padding-bottom: 1rem;
                        padding-right: 10%;
                        padding-left: 10%;
                    }
                    img{
                        width: 100%;
                        object-fit: contain;
                        object-position: center;
                        margin: auto;
                        display: block;
                        user-select: none;
                        height: 5rem;
                        @media(min-width:1400px){
                            height: 7rem;
                        }
                        @media(max-width:500px){
                            height: 20vw;
                        }
                    }
                    .content-title{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .card-title{
                            font-weight: bold;
                            margin-bottom: .3rem;
                            user-select: none;
                            font-size: .7rem;
                            text-align: center;
                            @media(min-width:1400px){
                                font-size: .8rem;
                            }
                            @media(max-width:500px){
                                font-size: 2.5vw;
                            }
                        }
                        .card-subtitle{
                            font-size: .7rem;
                            user-select: none;
                            @media(min-width:1400px){
                                font-size: .9rem;
                            }
                            @media(max-width:500px){
                                font-size: 3vw;
                            }
                        }
                    }
                }
                .btn-next,.btn-prev{
                    width: 3rem;
                    height: 3rem;
                    border-radius: 100%;
                    border: 1px solid #eaeaea;
                    position: absolute;
                    bottom: 0;
                    top: 0;
                    margin: auto;
                    background-repeat: no-repeat;
                    background-size: 40% 40%;
                    background-position: center;
                    cursor: pointer;
                    @media(max-width:1200px){
                        width: 2rem;
                        height: 2rem;
                    }
                    @media(max-width:500px){
                        width: 10.5vw;
                        height: 10.5vw;
                        background-color: white;
                    }
                }
                .btn-prev{
                    left: -0.5rem;
                    @media(max-width:500px){
                        left: -7vw;
                    }
                }
                .btn-next{
                    right: -0.5rem;
                    @media(max-width:500px){
                        right: -7vw;
                    }
                }
            }
        }
        .row{
            display: flex;
            // padding: 0 7vw;
            justify-content: space-between;
            margin-bottom: 4vw;
            border-color: #a5a5a5;
        }
        .p-6-m{
            @media(max-width:500px){  
                padding: 0 7vw;
            }
        }
        .btn-radio{
            @media(max-width:500px){        
                border: 1px solid ;
                width: 100%;   
                background: var(--red);
                border-color: var(--red);
                color: white;
            }
        }
        .btn-radio.middle{
            z-index: 1;
            @media(max-width:500px){  
                width: 48%;
                background: transparent;
                color: #a5a5a5;
                border-color: #a5a5a5;
            }
            &.active{
                background-color: red!important;
                border: 0;
                color: white;
            }
        }
    }
}
.lg-toolbar{
    background-color: transparent!important
}
.lg-toolbar .lg-group{
    background-color: transparent!important;
}

.Mapa-all.print{
    margin-bottom: 4rem;
}

.template.town{
    .section-filter{
        display: none;
    }
    .Mapa-all{
        margin-top: 3rem;
    }
}