.dialog-cart.town{
    --button:#233442!important;
    --active:#f1605f;
}

.scroll{
    &::-webkit-scrollbar-thumb {
        animation: .5s animateScroll forwards;
        background-color: rgb(88, 88, 88);
        opacity: .5;
        border-radius: 4px;
        position: fixed;
    }

    &::-webkit-scrollbar {
        width: 6px;     /* Tamaño del scroll en vertical */
        height: 6px;    /* Tamaño del scroll en horizontal */
    }
    
}
@keyframes animateScroll{
    0%{
        background: transparent;
    }
    100%{
        background: white;
    }
}
.content-all-cards{
    width: 100%;
    display: flex;
    position: relative;
}
.dialog-cart,.dialog-select{
    display: flex!important;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #0000006b;
    position: fixed;
    border: 0;
    top: 0;
    .popup-card,.popup-select{
        height: 14rem;
        width: 25rem;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 1rem;
        background: #fff;
        z-index: 1;
        animation: .7s openCart forwards;
        position: relative;
        left: -6rem;
        visibility: hidden;
        pointer-events: none;
        animation-duration: .7s;
        transition: .7s;
        font-size: 1.2rem;
        @media(max-width:500px){
            height: 10rem;
            font-size: .9rem;
            z-index: 8;
            width: 18rem;
            right: 0;
            left: 0;
            box-shadow: 0px 0px 10px 0px #00000040;
        }
        .icon-close{
            mask-size: contain;
            width: 1rem;
            height: 1rem;
            background: rgba(0, 0, 0, 0.582);
            position: absolute;
            top: 1rem;
            right: 1rem;
        }
        &.active{
            visibility: visible;
            pointer-events: all;

        }
        &.inactive{
            transition: .4s;
            opacity: 0;
            pointer-events: none;
            @media(max-width:700px){
            }
        }
    }
    .popup-select{
        left: 0;
    }
}

dialog{
    opacity: 0;
    z-index: -1;
    
}
dialog.site-dialog{
    opacity: 1;
    z-index: -1!important;
    visibility: hidden!important;
}
dialog.site-dialog[open]{
    opacity: 1;
    z-index: 1!important;
    visibility: visible!important;
}
dialog.hide{
    animation: .5s opacidad forwards;
    
    .popup-card{
        text-align: center;
        animation: .7s hidePopupCard forwards;
        @keyframes hidePopupCard {
            0%{
                opacity: 1;
                z-index: 2;
                display: flex;
                pointer-events: all;
            }
            99%{
                z-index: 1;
                opacity: 0;
                pointer-events: none;
                display: none;
            }
            100%{
                z-index: 1;
                opacity: 0;
                pointer-events: none;
                display: none;
            }
        }
    }
    .popup-card.inactive{
        z-index: 1;
        opacity: 0;
        pointer-events: none;
        display: none;
    }
    .content-cart-add{
        animation: .7s closeCart forwards;
    }
}
dialog.active{
    animation: .5s showModal forwards;
    .content-cart-add{
        animation: .7s openCart forwards;
    }
}
@keyframes showModal {
    0%{
        visibility: visible;
        z-index: -0;
        opacity: 0;

    }
    1%{
        z-index: 2;
    }
    100%{
        visibility: visible;
        z-index: 10;
        opacity: 1;
    }
}
@keyframes openCart {
    0%{
        right: -19.5rem;
    }
    100%{
        right: 0;
    }
}
@keyframes closeCart {
    0%{
        right: 0;
    }
    100%{
        right: -19.5rem;
    }
}
@keyframes opacidad{
    0%{
        visibility: visible;
        z-index: 1;
        opacity: 1;
        background: #0000006b;
        pointer-events: all;
    }
    2%{
        // background: transparent;
    }
    99%{
        z-index: 1;
        opacity: 1;
    }
    100%{
        background: transparent;
        visibility: visible;
        z-index: 1;
        opacity: 0;
        pointer-events: none;

    }
}
.icon-trush{
    width: 100%!important;
    height: 100%!important;
    mask-image: url("../../Assets/iconos/trush.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background: red!important;
    width: 100%;
    height: 100%;
    background: red;
}
.content-cart-add{
    position: fixed;
    right: 0;
    width: 19.5rem;
    height: 100vh;
    top: 0;
    background: white;
    border-left: 1px solid #21252928;
    box-shadow: 0px 0px 10px 0px #00000067;
    z-index: 2;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 6rem;
    display: flex;
    flex-direction: column;
    @media(max-width:540px){
        width: 100%;
    }
    
    .title-cart{
        text-align: center;
        color: #212529;
        padding: 1rem 0;
        margin: 0;
        font-family: "nexa-book";
        font-size: 1.1rem;
    }
    .item{
        color: #212529;
        display: flex;
        padding-bottom: .4rem;
        padding-top: .4rem;
        border-top: 1px solid #a5a5a528;
        min-height: 75px;
        align-items: center;
        .content-attr{
            display: flex;
            flex-direction: row;
            .item-price{
                font-size: .7rem;
            }
            .price{
                font-weight: bold;
            }
        }
        .item-title{
            color: #212529;
            font-size: 0.7rem;
            width: 4rem;
            img{
                width: 3rem;
                height: 2.5rem;
                object-fit: contain;
                object-position: center;
            }
        }
        .content-item{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
       
        .options-cart{
            display: flex;
            align-items: center;
            margin-left: auto;
            position: relative;
            .icon-cart{
                width: 1rem;
                min-width: 1rem;
                height: 1.2rem;
                margin: 0 .5rem;
                border: 1px solid var(--primary);
                background: var(--primary);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: .2rem;
                background: #f7f8f9;
                border-color: transparent;
                color: #5d5c5ca6;
                cursor: pointer;
                mask-image: none;
                background: transparent!important;
            }
            .quantity{
                width: 1.5rem;
                display: flex;
                font-size: .9rem;
                align-items: center;
                justify-content: center;
                padding-top: .1rem;
            }
            .btn-cart{
                width: 70%;
                height: 70%;
                background:  #212529;
                mask-repeat: no-repeat;
                mask-size: contain;
                mask-position: center;
                pointer-events: none;
            }
            .btn-cart-more{
                mask-image: url("../../Assets/iconos/mas.svg");
            }
            .btn-cart-less{
                mask-image: url("../../Assets/iconos/less.svg");;
            }
        }
    }
    
    .payment{
        position: absolute;
        color: white;
        margin-bottom: 1rem;
        bottom: 0;
        width: 90%;
        background: var(--dark);
        border-radius: 0!important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .5rem;
        transition: .5s;
        display: flex;
        align-items: center;
        &.hide{
            opacity: .5;
            pointer-events: none;
        }
    }
    .payment:hover{
        background: var(--red);
        color: white;
    }
}
.content-cart-list{
    .icon-trush{
        // mask-image: none;
    }
}
.content-cart-add.hide{
    animation: 1s move-x-hide forwards!important;
}
@keyframes move-x-hide{
    0%{
        transform:translateX(0%) ;
    }
    100%{
        transform:translateX(100%);
    }
}
.content-cart-add{
    .content-items{
        height: 89%;
        .hide{
            position: absolute;
            top: 6.5rem;
            right: 1rem;
            font-size: .65rem;
            text-decoration: underline;
            cursor: pointer;
            color: #21252994;
        }
    }
    .item:nth-child(1){
        border-top: 0px solid #a5a5a528;
    }
    .content-cart-list{
        overflow-y: scroll;
        overflow-x: hidden;
        height: 88%;
    }
    .content-cart-list::-webkit-scrollbar {
        width: .5rem; 
    }
    .content-cart-list::-webkit-scrollbar-thumb {
        background: rgba(33, 37, 41, 0.075);
        border-radius: .2rem;
    }
    .content-cart-list::-webkit-scrollbar-track {
        background: #0633770e;
    }
}