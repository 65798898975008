.Politics{
    width: 100%;
    align-items: center;
    justify-content: center;
    top: 0;
    &.active{
        display: flex;
    }
    .content-politicas{
        padding: 6rem 1.5rem 1.5rem;
        position: relative;
        @media(max-width:500px){
            padding-left: 1vw;
            padding-right: 1vw;
        }   
        .icon-close{
            width: 1rem;
            height: 1rem;
            background: #636363;
            position: absolute;
            right: 2rem;
            top: 1rem;
            cursor: pointer;
        }
        .content-scroll{
            height: 100%;
            padding: 1rem;
        }
        h2{
            font-size: 1.5rem;
            text-align: center;
            margin-bottom: 1rem;
            font-weight: bold;
        }
        .sub-title{
            font-size: 1.3rem;
            margin-bottom: 1rem;
            margin-top: 2.4rem;
            display: block;
            font-weight: 700;
            font-family: 'nexa-heavy';
        }
        .paragraph{
            margin-bottom: 1rem;
            font-size: 1rem;
            text-align: justify;
        }
        .bold{
            font-weight: bold;
        }
        .list{
            padding-left: 1rem;
        }
        .item{
            font-size: 1rem;
            display: flex;
            .line{
                margin-right: .5rem;
            }
        }
    }
}