.brands{
    margin-right: 5rem;
    --item:1.7rem;
    @media(min-width:1400px){
        margin-right: 6rem;
    }
    @media(max-width:500px){
        --item: 10vw;
    }
    .dci{
        mix-blend-mode: multiply;

    }
    .brand{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .item{
            margin-bottom: 2rem;
            @media(min-width:1400px){
                margin-bottom: 1rem;
            }
        }
        .casaideal{
            height:calc(var(--item));
            @media(max-width:600px){
                margin-bottom: 10vw;
            }
        }

        .content-item{
            display: flex;
            flex-direction: column;
            @media(max-width:550px){
                display: none;
            }
            .title{
                letter-spacing: .1rem;
                font-size: .4rem;
                margin-bottom: .5rem;
                @media(min-width:1400px){
                    font-size: .7rem;
                }
            }
            .two{
                display: flex;
                flex-wrap: nowrap;
                gap: 1.3rem;
            }
            .bestplace{
                height: 2rem;
                width: auto;
                @media(min-width:1400px){
                    height: 2.3rem;
                }
            }
            .asei,.dci{
                height: 2.5rem;
                margin-right: 0;
                object-fit: contain;
                object-position: 0;
                mix-blend-mode: multiply;
                @media(min-width:1400px){
                    height: 3.2rem;
                }
            }
            .dci{
                width: 100%;
                mix-blend-mode: multiply;

            }
            .capeco{
                height: 2.5rem;
                @media(min-width:1400px){
                    height: 3.2rem;
                }
            }
            .item{
                margin-bottom: 1.5rem;
                
            }
        }
        .bestplace{
            height:calc(var(--item) + 1.5rem);
            @media(max-width:600px){
                display: none;
            }
        }
        .miembros{
            height:calc(var(--item) + 2.5rem);
            @media(max-width:600px){
                display: none;
            }
        }
    }
    .brand1{
        width: 100%;
        height: 50%;
        display: flex;   
        padding-top: 1.5vw;         
        svg{
            width: 8.5vw;
            margin-left: 7.7vw;
        }
    }
}
.footer{
    width: 100%;
    background: #E9E9E9;
    z-index: 2;
    position: relative;
    .container{
        justify-content: center;
    }
    .column{
        display: flex;
        padding-top: 3rem;
        padding-bottom: 1rem;
        width: 100%;
        justify-content: space-between;
        @media(max-width:740px){
            flex-direction: column;
        }
        @media(max-width:740px){
            padding-left: 10vw;
            padding-right: 10vw;
        }
        .content-column{
            display:flex;
            gap: 4rem;
            @media(max-width:1400px){
                gap: 1rem;
            }
        }
        .footer-column{
            height: 100%;
            padding-top: 0;
            color: var(--Secondary);
            width: 14rem;
            @media(max-width:1400px){
                width: 11rem;
            }
            @media(max-width:900px){
                width: 8rem;
            }
            h5{
                font-size: .9rem;
                margin-bottom: .9rem;
                @media(min-width:1400px){
                    font-size: 1.1rem;
                }
                @media(max-width:500px){
                    font-size:3.5vw;
                }
            }
            .words{
                font-family: "nexa-book";
                font-size: 0.8rem;
                margin-bottom: .3rem;
                display: flex;
                color: #292929;
                @media(min-width:1400px){
                    font-size: 1rem;
                }
                .icono{
                    min-width: 1rem;
                    height: 1rem;
                    width: 1rem;
                    display: flex;
                    margin-right: .5rem;
                    svg{
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
        .item1{
            @media(max-width:740px){
                display: none;
            }
        }
        .item2{
            @media(max-width:740px){
                display: none;
            }
        }
        .item3{
            padding-right: 4.1vw;
        }
        .item4{
            @media(max-width:500px){
                width: 78vw;
            }
        }
        .item5{    
            @media(min-width:500px){
                display: none;
            }
            @media(max-width:500px){
                display: flex;
                flex-direction: column;
                margin-top: 2vw;
                .content-item{
                    display: flex;
                    margin-right: 0;
                    gap: 6.8vw;
                    margin-top: .5rem;
                }
                .item{
                    height: 14vw;
                    mix-blend-mode: multiply;
                }
                .dci{
                    margin-top: 0;
                    height: 13vw;
                }
                .bestplace{
                    width: 31vw;
                }
                .miembros{
                    width: 29vw;
                    object-fit: contain;
                }
                .title{
                    font-size: 2.9vw;
                    top: 3vw;
                    position: relative;
                    margin-bottom: 4vw;
                    display: block;
                }
            }
        }
    }
    .copyright{
        width: 100%;
        height: 21%;
        background-color: #f5f5f5;
        display: flex;
        align-items: center;
        justify-content: center;
        .content_copyright{
            font-family: "nexa-book";
            font-size: .6rem;
            left: 0;
            position: relative;
            padding: .5rem 0;
            color: black;
            a{
                color: black;
            }
            @media(max-width:740px){
                font-size: .7rem;
            }
            @media(max-width:500px){
                font-size: 2.5vw;
            }
        }
    }
}
