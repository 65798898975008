@font-face {
    font-family: "nexa-black";
    font-display: auto;
    src: local("nexa-black"), url("../../fonts/nexa-black.woff");
}
@font-face {
    font-family: "nexa-bold";
    font-display: auto;
    src: local("nexa-bold"), url("../../fonts/nexa-bold.woff");
}
@font-face {
    font-family: "nexa-book";
    font-display: auto;
    src: local("nexa-book"), url("../../fonts/nexa-book.woff");
}
@font-face {
    font-family: "nexa-heavy";
    font-display: auto;
    src: local("nexa-heavy"), url("../../fonts/nexa-heavy.woff");
}
@font-face {
    font-family: "nexa-light";
    font-display: auto;
    src: local("nexa-light"), url("../../fonts/nexa-light.woff");
}
@font-face {
    font-family: "nexa-regular";
    font-display: auto;
    src: local("nexa-regular"), url("../../fonts/nexa-regular.woff");
}
@font-face {
    font-family: "nexa-thin";
    font-display: auto;
    src: local("nexa-thin"), url("../../fonts/nexa-thin.woff");
}
@font-face {
    font-family: "Poppins-Regular";
    font-display: auto;
    src: local("nexa-thin"), url("../../fonts/Poppins-Regular.woff");
}
@font-face {
    font-family: "Poppins-Bold";
    font-display: auto;
    src: local("nexa-thin"), url("../../fonts/Poppins-Bold.woff");
}
@font-face {
    font-family: "Poppins-SemiBold";
    font-display: auto;
    src: local("nexa-thin"), url("../../fonts/Poppins-SemiBold.woff");
}

.semi-bold-poppins{
    font-family: "Poppins-SemiBold";
}

.bold-poppins{
    font-family: "Poppins-Bold";
}
.nexa-bold {
    font-family: "nexa-bold";
}
.nexa-regular{
    font-family: "nexa-regular";
}
.nexa-heavy {
    font-family: "nexa-heavy";
}
.nexa-book{
    font-family: "nexa-book";
}

.m-light{
    font-family: "nexa-light";
}
.m-bold{
    font-family: "Montserrat-Bold"
}
.bold{
    font-weight: bold;
}