

.Mapa-all.nou{
    @media(min-width:1400px){
        margin-bottom: 10rem;
    }
}
.Mapa-all.stay{
    @media(min-width:1400px){
        margin-bottom: 10rem;
    }
}
.Mapa-all{
    width: 100%;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 1rem;
    @media(min-width:1400px){
        margin-bottom: 1rem;
    }
    @media(max-width:500px){
        margin-top: 10vw;
        margin-bottom: 0;
    }
    *{
        font-family: "nexa-book";
    }
    .title{
        @media(max-width:1100px){
            text-align: center;
        }
        .bold{
            font-family: "nexa-heavy";
        }
    }
    .content-row{
        display: flex;
        align-items: center;
    }
    .container.mapa{    
        max-width: 100%;
        padding: 0;
    }
    .container{
        .content-row{
            @media(max-width:1100px){
                display: flex;
                align-items: center;
                justify-content: center;
            }
            @media(max-width:500px){
                flex-direction: column;
            }
        }
        .mapa{
            width: 56.45vw;
            position: absolute;
            top: 3rem;
            display: flex;
            left: 6.7rem;
            @media(min-width:1400px){
                left: 11rem;
            }
            @media(max-width:1100px){
                position: relative;
                top: 0;
                display: flex;
                left: 0;
                margin-top: 3rem;
            }
            @media(max-width:500px){
                margin-top: 1rem;
            }
            @media(max-width:1100px){
                width: 100vw;
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
        .info-mapa{
            display: flex;
            flex-direction: column;
            position: relative;
            top: -1.8rem;
            right: 0;
            width: 35%;
            padding-bottom: 3rem;
            padding-left: 5rem;
            padding-top: 5rem;
            background: var(--dark);
            margin-left: auto;
            @media(max-width:1100px){
                display: none;
            }
            @media(max-width:500px){
                display: flex;
                width: 100%;
                padding-left: 0;
                align-items: center;
                padding-top: 12vw;
                top: 0;
            }
            .title{
                width: 17rem;
                color: white;
                margin-bottom: 1.5rem;
                font-size: 1.2rem;
                @media(min-width:1400px){
                    font-size: 1.4rem;
                    margin-bottom: 1rem;
                }
                @media(max-width:500px){
                    font-size: 5vw;
                }
            }
            .flex-column{
                margin-bottom: 1vw;
            }
            .flex-column:last-child{
                .sub-title{
                    margin-bottom: 0;
                }
            }
            .sub-title{
                color: var(--red);
                font-size: .8rem;
                margin-bottom: .2rem;
                @media(min-width:1400px){
                    font-size: 1.2rem;
                    margin-bottom: .3rem;
                }
                @media(max-width:500px){
                    font-size: 4.5vw;
                }
            }
            .direction{
                color: white;
                font-size: 0.8rem;
                @media(min-width:1400px){
                    font-size: 1rem;
                }
                @media(max-width:740px){
                    display: none;
                }
            }
        }
    }
}