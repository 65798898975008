.thanks{
    overflow: hidden;
    height: 100vh;
    min-height: 700px;
    position: relative;
    .container{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .message-icon{
        width: 7rem;
        margin-bottom: 1rem;
    }
    .title{
        font-size: 1.4rem;
    }
    .paragraph{
        width: 30rem;
        font-size: 1rem;
        text-align: center;
        @media(max-width:700px){
            width: 100%;
        }
    }
    .button{
        font-size: 1rem;
        color: black;
        cursor: pointer;
        width: 167px;
        height: 3rem;
        /* UI Properties */
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #E2E2E2;
        border-radius: 39px;
        opacity: 1;
        width: max-content;
        padding: 0 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: .1rem;
    }
    .tramado-3{
        position: absolute;
        bottom: -10rem;
        width: 30rem;
        left: 0;
        right: 0;
        margin: auto;
        @media(max-width:1000px){
            width: 85%;
            bottom: 0rem;
        }
    }
    .tramado-2{
        bottom: 10%;
        transform: translate(50%);
    }
    .tramado-1{
        top: 10%;
        width: 20rem;
        transform: translate(-50%);
        opacity: .7;
    }
}