.store{
    background-color: #F2F2F2;
}
.Main-pages{
    overflow: hidden;
}
.SliderDepartamentos{
    @media(max-width:1400px){    
        height: 16vw;
    }
    @media(max-width:500px){
        height: 50vw;
    }
}
.All-projects{
    position: relative;
    padding-bottom: 12rem;
    z-index: 0;
    @media(max-width:500px){
        padding-bottom: 15rem;
    }
}
.message-project{
    min-height: 500px;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width:500px){
        width: 100%;
        font-size: 1rem;
        line-height: 1rem;
        text-align: center;
        padding:0 2rem;
    }
}
.Filter{
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    transform: translateY(-50%);
    @media(max-width:1300px){
        transform: translateY(-28%);
    }
    @media(max-width:1300px){
        transform: translateY(-10%);
    }
    @media(max-width:500px){
        transform: translateY(0);
        top: -1.7rem;
        height: 3.4rem;
        z-index: 2;
    }
    .form-filter{
        width: 100%;
        display: flex;
        justify-content: center;
        @media(max-width:1300px){
            flex-direction: column;
        }
        @media(max-width:500px){
            background-color: white;
            border-radius: 1.5rem;
            padding-bottom: 1rem;
        }
        .btn-submit{
            border: 0;
            font-size: 1rem;
            height: 3.7rem;
            padding: 0 2.5rem;
            cursor: pointer;
            @media(max-width:1300px){
                width: 10rem;
                margin: auto;
                margin-top: 1rem;
            }
            @media(max-width:500px){
                height: 2.7rem;
            }
        }
    }
    .content-filter{
        width: auto;
        display: flex;
        align-items: center;
        padding: 0 .5rem;
        width: 100%;
        @media(max-width:1300px){
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            
        }
        @media(min-width:500px){
            height: auto!important;
        }
        @media(max-width:500px){
            padding: 0 1rem;
            align-items: flex-start;
            height: 3.5rem;
            overflow: hidden;
            transition: .5s;
            position: absolute;
        }
        &.active{
            @media(min-width:500px){
                height: auto!important;
            }
        }
        .title-filter{
            display: flex;
            padding: 0 1.5rem;
            align-items: center;
            justify-content: space-between;
            font-size: 4vw;
            width: 100%;
            background-color: white;
            border-radius: 100rem;
            font-family: 'nexa-bold';
            height: 3.4rem;
            @media(min-width:500px){
                display: none;
            }
            .mask{
                background-color: black;
                height: 1.1rem;
                width: 1.1rem;
            }
            .more{
                mask-image: url("../../Assets/iconos/more-filter.svg");
                
            }
            .less{
                mask-image: url("../../Assets/iconos/less-filter.svg");
            }
        }
        .content-inputs{
            display: flex;
            background-color: white;
            border-radius: 100rem;
            margin-right: 1rem;
            @media(max-width:1300px){
                flex-wrap: wrap;
                justify-content: center;
                border-radius: 1.5rem;
                padding-bottom: 1rem;
            }
            @media(max-width:500px){
                margin-right: 0;
                width: 100%;
                padding: 0 1.5rem;
            }
        }
        .content-input{
            padding: .8rem 1.5rem;
            padding-top: 0;
            padding-bottom: 1rem;
            height: 4rem;
            border-right: 1px solid rgb(231, 231, 231);
            display: flex;
            align-items: flex-end;
            @media(max-width:1300px){
                border-right: 0;
            }
            @media(max-width:500px){
                padding: 0;
                width: 100%;
                height: 3rem;
            }
            &:last-child{
                border-right: 0;
            }
            &:nth-child(3){
                @media(max-width:1300px){
                    border-right: 0;
                }
            }
            &.range{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-end;
                @media(max-width:1300px){
                    flex-direction: row;
                }
                @media(max-width:500px){
                    flex-direction: column;
                    border-radius: 1rem;
                    border: 1px solid #d8d8d8;
                    height: auto;
                    min-width: calc(100% + 1.6rem);
                    padding: 1rem .8rem;
                    margin-top: 1.4rem;
                }
                @media(min-width:500px){    
                    align-items: flex-end;
                }
                @media(min-width:1300px){
                    align-items: flex-start;
                }
                .title{
                    font-size: .8rem;
                    @media(max-width:1300px){
                        font-size: .9rem;
                    }
                    @media(max-width:500px){
                        margin-bottom: 0.5rem;
                    }
                }
            }
            .content-radio{
                display: flex;
                margin-bottom: .6rem;
                &:last-child{
                    margin-bottom: 0rem;
                }
            }
            .radio-input{
                visibility: hidden;
                position: absolute;
            }
            .select-radio{
                min-width: 1rem;
                height: 1rem;
                background-color: white;
                border-radius: .2rem;
                border: 1px solid rgb(212, 212, 212);
                display: flex;
                margin-right: 0.5rem;
            }
            .radio-input:checked ~ label{
                background-color: red;
                border-color: red;
            }
        }
        .title{
            font-size: 1rem;
            margin-right: .5rem;
            @media(max-width:1400px){
                font-size: .9rem;
            }
            @media(max-width:1300px){
                width: 6rem;
            }
            @media(max-width:500px){
                font-size: 4vw;
                min-width: 27vw;
            }
        }
       
        .select{
            border: 0;
            border-bottom: 1px solid black;
            width: 10rem;
            margin-bottom: 0;
            margin-top: 0;
            padding-bottom: 0;
            padding-left: 0;
            background-color: white;
            width: auto;
            @media(max-width:1700px){
                width: 6rem;
            }
            @media(max-width:1300px){
                width: 12rem;
            }
            @media(max-width:500px){
                width: 100%;
            }
            
            &.range{
                width: auto;
                font-size: .8rem;
                @media(max-width:500px){
                    font-size: 4vw;
                }
            }
            &.proyectos,&.dorms,&.vistas{
                
            }
        }
    }
}
.container-shop{
    margin: auto;
    @media(min-width:500px){
        max-width: calc(100vw - 2rem);
    }
    @media(min-width:750px){
        max-width: 700px;
    }
    @media(min-width:900px){
        max-width: 850px;
    }
    @media(min-width:1000px){
        max-width: 950px;
    }
    @media(min-width:1100px){
        max-width: 1050px;
    }
    @media(min-width:1300px){
        max-width: 1250px;
    }
    @media(min-width:1500px){
        max-width: 1450px;
    }
    @media(min-width:1700px){
        max-width: 1650px;
    }
    .slick-track .slick-slide > div{
        @media(max-width:500px){
            padding-left: 1rem;
        }
    }
    .targets{
        width: 100%;
        display: flex;
        flex-direction: column;
        
        .resume{
            width: 100%;
            height: 1px;
            background-color: rgb(102, 102, 102);
            margin-bottom: 2rem;
            margin-top: 1.1rem;
            display: flex;
            align-items: center;
            @media(max-width:500px){
                display: none;
            }
            .radius{
                padding: .5rem 1rem;
                padding-bottom: .3rem;
                cursor: pointer;
                img{
                    width: .7rem;
                    margin-left: .5rem;
                    transition: .5s;
                    transform: rotate(180deg);
                    &.close-card{
                        transform: rotate(0deg);
                    }
                }
            }
            img{
                width: 2.5rem;
                cursor: pointer;
            }
            
        }
    }
    .content-project{
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: flex;
        position: relative;
        @media(max-width:800px){
            flex-direction: column; 
        }
        .reference-project{
            width: 22rem;
            display: flex;
            flex-direction: column;
            margin-right: 1.2rem;
            @media(max-width:800px){
                width: auto;
            }
            @media(max-width:500px){
                padding-left: 1rem;
                margin-bottom: .4rem;
            }
            .content-text{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: .7rem;
                @media(max-width:800px){
                    justify-content: flex-start;
                }
                .text{
                    text-transform: uppercase;
                    @media(max-width:900px){
                        margin-right: .8rem;
                    }
                    @media(max-width:500px){
                        display: flex;
                        align-items: center;
                        position: relative;
                        top: .1rem;
                        font-size: 4vw;
                    }
                    strong{
                        @media(max-width:500px){
                            margin-left: .5rem;
                        }
                        font-family: "nexa-heavy";
                    }
                }
                .radius{
                    padding: .5rem .7rem;
                    // padding-bottom: .2rem;
                    font-size: .8rem;
                    line-height: .8rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media(max-width:500px){
                        font-size: .7rem;
                    }
                }
            }
            .content-img{
                width: 100%;
                border-radius: .8rem;
                overflow: hidden;
                @media(max-width:800px){
                    display: none;
                }
                img{
                    width: 100%;
                }
            }
        }
    }
    .content-cards{
        width: 100%;
        display: grid;
        gap: 1rem;
        grid-auto-rows: minmax(0,auto);
        grid-template-rows: auto;
        grid-template-columns: repeat(auto-fill,minmax(15rem,1fr));
        grid-auto-flow: row dense;
        @media(max-width:1400px){
            grid-template-columns: repeat(auto-fill,minmax(14rem,1fr));
        }
        @media(max-width:500px){
            display: flex;
            flex-direction: column;
            overflow: inherit;            
            .content-slider-card{
                // overflow: hidden;
                .slick-list{
                    overflow: inherit;
    
                }
            }
        }
        // grid-auto-flow: dense;
        .slick-list{
            width: 100vw;
        }
        .progress-container{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: .5rem;
            progress[value] {
                width: calc(100vw - 8rem);
                margin-right: .5rem;
                -webkit-appearance: none;
                appearance: none;
                height: 8px;
                overflow: hidden;
                border-radius: 4px;
                @media(max-width:500px){
                    height: 4px;
                }
                &::-webkit-progress-bar {
                    background-color: #e4e4e4;
                    width: 100%;
                }
                
                &::-webkit-progress-value {
                    transition: width 0.5s;
                    background-color: var(--red) !important;
                }
            }
            label{
                font-family: "Poppins-Regular";
            }
        }
    }
    
    .card{
        width: 100%;
        background-color: white;
        border-radius: .9rem;
        font-size: 1rem;
        @media(max-width:1400px){
            font-size: .9rem;
        }
        @media(max-width:850px){
            margin: 0!important;
        }
        @media(max-width:500px){
            width: 77vw!important;
            .content-card{
                background-color: white;
                width: 100%;
                border-radius: .9rem;
                overflow: hidden;
            }
        }
        &.hide{
            display: none;
        }
        .header{
            display: flex;
            justify-content: space-between;
            font-family: "Poppins-Regular";
            padding: 0 1em;
            border-bottom: 1px solid rgb(233, 233, 233);
            height: 3rem;
            display: flex;
            align-items: center;
            .type{
                font-size: .9em;
                line-height: .9em;
                text-transform: uppercase;
                margin-right: 1rem;
                position: relative;
                top: .1rem;
                @media(max-width:400px){
                    font-size: 4vw;
                }
            }
            .dorms{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: .2rem .6rem;
                font-size: .8rem;
                white-space: nowrap;
                @media(max-width:500px){
                    font-size: .7rem;
                }
            }
        }
        .content-img{
            width: 100%;
            height: 15rem;
            border-bottom: 1px solid rgb(233, 233, 233);
            position: relative;
            @media(max-width:1400px){
                height: 11rem;
            }
            @media(max-width:500px){
                height: 17rem;
            }
            .lupa{
                position: absolute;
                width: 3rem;
                height: 3rem;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                pointer-events: none;
                opacity: 0;
                transition: .5s;
                @media(max-width:500px){
                    opacity: 1;
                    pointer-events: all;
                }
            }
            @media(min-width:500px){
            
                &:hover{
                    .lupa{
                        opacity: 1;
                        pointer-events: all;
                        cursor: pointer;
                    }
                }
            }
            .img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
            span{
                height: 100%;
                position: absolute;
                display: flex;
                width: 100%;
                .react-loading-skeleton{
                    height: 100%;
                    border-radius: 0;
                    background-color: #6e6e6e;
                    &::after{
                        background-image: none;
                        background: linear-gradient(
                            90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.06206232492997199) 47%, rgba(0,0,0,0) 100%);
                    }
                }
            }
        }
        .info{
            display: flex;
            font-family: "nexa-regular";
            padding: 0 0;
            position: relative;
            .title-info{
                font-size: .7em;
                line-height: .9em;
            }
            .sub-title{
                line-height: .9em;
                font-size: .7em;
            }
            .select-header{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1rem;
                height: 3rem;
                background: #fff;
                z-index: 3;
                cursor: pointer;
                .inner{
                    display: flex;
                    font-size: .8rem;
                    .name{
                        font-size: .8rem;
                    }
                    .price-column{
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        top: -2px;
                    }
                }
                .icon{
                    width: 1rem;
                    margin-left: .5rem;
                    transition: .5s;
                    img{
                        width: 100%;
                    }
                }
                &.active{
                    .icon{
                        transform: rotate(180deg);
                    }
                }
            }
            .area{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                width: 36%;
                border-right: 1px solid rgb(233, 233, 233);
            }
            .price{
                padding: 0 0;
                padding-bottom: 0;
                position: absolute;
                bottom: 0;
                display: flex;
                justify-content: flex-end;
                width: 100%;
                left: 0;
                background: #fff;
                transform: translateY(calc(100% - .3rem));
                z-index: 2;
                border: 1px solid #e5e5e5;
                border-radius: 0 0 0.8rem 0.8rem;
                transition: .2s;
                .content-price{
                    display: flex;
                    flex-direction: column;
                }
                .price-column{
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    top: 3px;
                    cursor: pointer;
                   
                }
                .name,.meters,.price-column{
                    font-size: .8rem;
                }
            }
            .price{
                display: flex;
                flex-direction: column;
                overflow: hidden;
            }
            
            .prices{
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: .8rem;
                height: 3rem;
                min-height: 3rem;
                position: relative;
                cursor: pointer;
                &:hover{
                    background-color: rgba(0, 0, 0, 0.158);
                }
                &::after{
                    content: "";
                    width: 100%;
                    height: 1px;
                    background-color: rgba(0, 0, 0, 0.068);
                    position: absolute;
                    bottom: 0;
                }
            }
            .prices:last-child{
                &::after{
                    display: none;
                }
            }
        }
        .separe-depa{
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .9em;
            border-radius: 0 0 .8rem .8rem;
            cursor: pointer;
            @media(max-width:400px){
                font-size: 4.5vw;
            }
            .opacity{
                opacity: .7;
            }
        }
    }   
}