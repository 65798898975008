.content-modal{
    width: 100%;
    height: 100%;
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    display:flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
    &::before{
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        filter: blur(5px);
        backdrop-filter: blur(5px);
        background-color: rgba(0, 0, 0, 0.5);
    }
    .modal{
        z-index: 1;
        width: 32rem;
        height: max-content;
        max-height: 35rem;
        position: relative;
        .icon-close{
            width: 1rem;
            height: 1rem;
            background: white;
            position: absolute;
            right: -2rem;
            top: -2rem;
            cursor: pointer;
        }
        img{
            width: 100%;

        }
    }
}